import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  ScrollView,
  TouchableWithoutFeedback,
  FlatList,
  SafeAreaView
} from "react-native";
// Customizable Area End

import FilteritemsController, { Props } from "./FilteritemsController";
import Icon from "react-native-vector-icons/FontAwesome";
export default class Filteritems extends FilteritemsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getList(item: any) {
    let value = item.item.attributes;
    console.log(value, "getList");
    return (
      <View style={styles.productBoxFilterItemsRN}>
        <View style={styles.ImgContainerFilterItemsRN}>
          <Image
            style={styles.productImgFilterItemsRN}
            source={{ uri: value.images[0].url }}
            resizeMode="cover"
          />
        </View>
        <View style={styles.detailContentFilterItemsRN}>
          <Text style={styles.productNameFilterItemsRN}>{value.name}</Text>
          <Text style={styles.priceFilterItemsRN}>{value.price}</Text>
          <View style={[styles.flexBoxFilterItemsRN, styles.starBoxFilterItemsRN]}>
            <Text style={styles.ratingFilterItemsRN}>{value.average_rating}</Text>
            <Icon
              name="star"
              size={12}
              style={styles.sortIcon}
              color="orange"
            />
          </View>
        </View>
      </View>
    );
  }
  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      <SafeAreaView style={styles.container}>
      {/* Customizable Area Start */}
        <View style={styles.topContainerFilterItemsRN}>
          <TouchableOpacity
            style={[styles.topBoxFilterItemsRN, styles.flexBoxFilterItemsRN]}
            onPress={() => this.navigateToFilter()}
            testID={"navigateToFilter"}
          >
            <Icon
              name="filter"
              size={15}
              style={styles.sortIcon}
              color="#808080"
            />
            <Text style={styles.topTextFilterItemsRN}>Filter</Text>
          </TouchableOpacity>
        </View>
        <ScrollView keyboardShouldPersistTaps="always">
          <TouchableWithoutFeedback
            onPress={() => {
              this.hideKeyboard();
            }}
          >
            {/* Customizable Area Start */}
            {/* Merge Engine UI Engine Code */}
            <View>
              <View style={[styles.productContainerFilterItemsRN, styles.flexBoxFilterItemsRN]}>
                <FlatList
                  data={this.state.data}
                  renderItem={item => this.getList(item)}
                  extraData={this.state}
                  keyExtractor={(item: any) => item.id}
                  numColumns={2}
                  columnWrapperStyle={styles.rowFilterItemsRN}
                />
              </View>
            </View>
            {/* Merge Engine UI Engine Code */}
            {/* Customizable Area End */}
          </TouchableWithoutFeedback>
        </ScrollView>
      {/* Customizable Area End */}
      </SafeAreaView>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    maxWidth: 650,
    backgroundColor: "#ffffffff"
  },
  topContainerFilterItemsRN: {
    display: "flex",
    borderBottomWidth: 1,
    shadowOpacity: 1.0,
    borderBottomColor: "#ccc",
    shadowOffset: { width: 1, height: 1 },
    flexDirection: "row",
    shadowColor: "black",
    backgroundColor:"#FFF"
  },
  topBoxFilterItemsRN: {
    paddingVertical: 5,
    width: "100%",
    justifyContent: "center",
    marginVertical: 12,
    alignItems: "center"
  },
  topTextFilterItemsRN: {
    textAlign: "center",
    fontSize: 16,
    color: "#000"
  },
  rightBorderFilterItemsRN: {
    borderRightWidth: 1,
    borderRightColor: "#ccc"
  },
  modalContainerFilterItemsRN: {
    padding: 15,
    backgroundColor: "white",
    minHeight: 260,
    position: "relative"
  },
  modalBoxFilterItemsRN: {
    margin: 0,
    justifyContent: "flex-end",
    width: "100%",
    marginBottom: -20
  },
  closeIconFilterItemsRN: {
    position: "absolute",
    top: 15,
    right: 15,
    fontWeight: "normal",
    zIndex: 999
  },
  headingFilterItemsRN: {
    fontSize: 18,
    color: "#000",
    marginBottom: 15
  },
  sortListFilterItemsRN: {
    paddingVertical: 8,
    alignItems: "center"
  },
  rowFilterItemsRN: {
    flex: 1,
    justifyContent: "space-between"
  },
  sortTextFilterItemsRN: {
    fontSize: 16,
    color: "#696969"
  },
  sortIcon: {
    marginRight: 12
  },
  flexBoxFilterItemsRN: {
    display: "flex",
    flexDirection: "row"
  },
  productContainerFilterItemsRN: {
    width: "100%",
    flex: 1,
    justifyContent: "center",
    backgroundColor: "white"
  },
  productBoxFilterItemsRN: {
    borderBottomWidth: 1,
    height: 250,
    flex: 0.5,
    borderRightWidth: 1,
    flexDirection: "column",
    borderColor: "#ccc"
  },
  ImgContainerFilterItemsRN: {
    //marginBottom: 15,
    height: 150
  },
  productNameFilterItemsRN: {
    paddingVertical: 5,
    fontSize: 16
  },
  priceFilterItemsRN: {
    color: "#444",
    fontSize: 16,
    marginBottom: 10
  },
  productImgFilterItemsRN: {
    width: "100%",
    height: "100%"
  },
  detailContentFilterItemsRN: {
    padding: 10
  },
  ratingFilterItemsRN: {
    color: "#000",
    paddingRight: 6
  },
  starBoxFilterItemsRN: {
    alignItems: "center"
  }
});
// Customizable Area End
