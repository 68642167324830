import React from "react";

import {
  Typography,
  IconButton,
  // Customizable Area Start
  CircularProgress,
  Button,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { FileCopyOutlined, EditOutlined } from "@material-ui/icons";
import FilterBy from "./FilterBy";
import MainLayout from "../../../components/src/MainLayout.web";
import Table from "../../../components/src/Table.web";
import { debounce, formatPrice } from "../../../components/src/utilities/utils";
import './Quotemanagement.css';
import Dialog from "../../../components/src/Dialog.web";

// Customizable Area End

import QuotemanagementController, {
  Props,
  configJSON,
} from "./QuotemanagementController";
import QuoteDetailsView from "./QuoteDetailsView.web";
import { withTranslation } from 'react-i18next';
import { SavedQuotesTableName, ConfirmedQuotesTableName } from "../../../components/src/tableNameConstants";
import { DeleteQuoteDialog } from "./DeleteQuoteDialog.web";
import QuoteDetailsUpdateWeb from "./QuoteDetailsUpdate.web";
import CustomTooltip from "../../../components/src/Tooltip.web";
import ConfirmQuoteDetailsViewWeb from "./ConfirmQuoteDetailsView.web";


export class Quotemanagement extends QuotemanagementController {
  private dialogRef: React.RefObject<Dialog>;
  handleScroll: any = null;
  handleScrollConfirmed : any = null ;
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.dialogRef = React.createRef<Dialog>();
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.onInputFocus = this.onInputFocus.bind(this)
    this.handleScroll = debounce(this.scrollTable, 300)
    this.handleScrollConfirmed = debounce(this.scrollConfirmedQuotesTable , 300)
    // Customizable Area End
  }

  // Customizable Area Start
  openDialog() {
    if (this.dialogRef.current && typeof this.dialogRef.current.openDialog === 'function') {
      this.dialogRef.current.openDialog();
    } else {
      console.warn("Dialog reference or method openDialog not available");
    }
  }

  closeDialog() {
    if (this.dialogRef.current && typeof this.dialogRef.current.closeDialog === 'function') {
      this.dialogRef.current.closeDialog();
    } else {
      console.warn("Dialog reference or method closeDialog not available");
    }
  }

  onInputFocus() {
    if (this.state.errorMessage) {
      this.setState({ errorMessage: '' });
    } else {
      console.log("No error message to clear");
    }
  }

  
  addProductToCart(type: string) { 
    const { confirmedQuotesData, productQuantities } = this.state;
    const quoteItems = confirmedQuotesData.quote?.quote_order_items;
    if (!quoteItems || quoteItems.length === 0) {
      console.warn('No items found in the quote_order_items array');
      return;
    }
  
    // Create an array of promises for all products
    const updatePromises = quoteItems.map((quoteData: any) => {
      const key = `id_${quoteData?.product_id}`;
      const qty = productQuantities[key];
  
      if (typeof qty !== 'string') {
        this.setState({ clickedProductId: quoteData?.product_id });
  
        if (type === 'YES') {
          this.cleanCartItems();
        }
  
        return new Promise((resolve) => {
          setTimeout(() => {
            this.updateProductQuantityFromCart(
              quoteData?.product_id,
              quoteData?.quantity,
              quoteData?.quantity === 0 ? 'DELETE' : 'UPDATE'
            )?.then(resolve);
          }, 1000);
        });
      } else {
        console.warn('Invalid quantity type for product ID');
        return Promise.resolve(); // Resolve immediately for invalid quantities
      }
    });
  
    // Wait for all updates to complete before navigating to the cart
    Promise.all(updatePromises).then(() => {
      this.navigateToCart();
    });
  }
  

  openPopup(confirmQuoteItem: any) {
    this.setState({ confirmedQuotesData: confirmQuoteItem }, () => {
      const customer_id: string | null = localStorage.getItem('customer_id');
      const data = JSON.parse(localStorage.getItem(`cart_${customer_id}`) || '{"products": {}}');
      if (Object.keys(data.products).length > 0) {
        this.openDialog();
      } else {
        // Immediately call addProductToCart on the first click
        this.addProductToCart('YES');
      }
    });
  }
  

  renderQuoteDetailsView = (state: any) => {
    const {
      activeQuote,
      savedQuotesData,
      loading,
      view,
      addresses,
      newAddressLoading,
      newAddressSubmitted,
      isOrderLoading,
      isOrderSubmitted,
      orderMethod
    } = state;

    return (
      <QuoteDetailsView
        page={activeQuote.page}
        id={activeQuote.id}
        activeQuote={savedQuotesData}
        loading={loading}
        submitOrder={this.submitOrder}
        view={view}
        createNewAddress={this.createNewAddress}
        submitOrderDialogClose={this.submitOrderDialogClose}
        navigateToOrderDetails={this.navigateToOrderDetails}
        createdOrderDetails={state.createdOrderDetails}
        addresses={addresses}
        newAddressLoading={newAddressLoading}
        newAddressSubmitted={newAddressSubmitted}
        isOrderLoading={isOrderLoading}
        isOrderSubmitted={isOrderSubmitted}
        quantityUpdate={this.quantityUpdate}
        orderMethod={orderMethod}
        changeOrderMethod={this.changeOrderMethod}
        fetchQuoteDetail={this.fetchQuoteDetail}
        submitOffer={this.submitOffer}
        DeleteQuote={this.deleteQuoteIfProductDeletedBE}
        isOfferLoading={this.state.isOfferLoading}
      />
    );
  };
  renderQuoteDetailsUpdate = (state: any) => {
    const {
      activeQuote,
      savedQuotesData,
      loading,
      view,
      addresses,
      newAddressLoading,
      newAddressSubmitted,
      isOrderLoading,
      isOrderSubmitted,
      orderMethod
    } = state;

    return (
      <QuoteDetailsUpdateWeb
        page={activeQuote.page}
        id={activeQuote.id}
        activeQuote={savedQuotesData}
        loading={loading}
        submitOrder={this.submitOrder}
        view={view}
        createNewAddress={this.createNewAddress}
        submitOrderDialogClose={this.submitOrderDialogClose}
        navigateToOrderDetails={this.navigateToOrderDetails}
        createdOrderDetails={state.createdOrderDetails}
        addresses={addresses}
        newAddressLoading={newAddressLoading}
        newAddressSubmitted={newAddressSubmitted}
        isOrderLoading={isOrderLoading}
        isOrderSubmitted={isOrderSubmitted}
        quantityUpdate={this.quantityUpdate}
        orderMethod={orderMethod}
        changeOrderMethod={this.changeOrderMethod}
        cart={this.state.cart}
        fetchCartOnEveryUpdate={this.fetchCartOnEveryUpdate}
        deleteQuoteItem={this.deleteQuoteItem}
        quantity={this.state.quantity}
        product_id={this.state.product_id}
        cartData={this.state.cartData}
        submitOffer={this.submitOffer}
        DeleteQuote={this.deleteQuoteIfProductDeletedBE}
        isOfferLoading={this.state.isOfferLoading}
      />
    );
  };
  renderConfirmQuoteDetailsView = (state: any) => {
    const {
      activeQuote,
      confirmQuotesData,
      loading,
      view,
      addresses,
      newAddressLoading,
      newAddressSubmitted,
      isConfirmOrderLoading,
      isConfirmOrderSubmitted,
      orderMethod
    } = state;

    return (
      <ConfirmQuoteDetailsViewWeb
        page={activeQuote.page}
        id={activeQuote.id}
        activeQuote={confirmQuotesData}
        loading={loading}
        submitOrder={this.placeOrderFromConfirmQuotes}
        view={view}
        createNewAddress={this.createNewAddress}
        submitOrderDialogClose={this.submitOrderDialogClose}
        navigateToOrderDetails={this.navigateToOrderDetails}
        createdOrderDetails={state.createdOrderDetails}
        addresses={addresses}
        newAddressLoading={newAddressLoading}
        newAddressSubmitted={newAddressSubmitted}
        isOrderLoading={isConfirmOrderLoading}
        isOrderSubmitted={isConfirmOrderSubmitted}
        quantityUpdate={this.quantityUpdate}
        orderMethod={orderMethod}
        changeOrderMethod={this.changeOrderMethod}
        fetchQuoteDetail={this.fetchQuoteDetail}
      />
    );
  };
  renderDefaultView = (state: any, t: any, configJSON: any) => {
    const { loading } = state;

    return (
      <>
        {loading ? (
          <div className="loading-section" data-testid="orders-loading">
            <CircularProgress color="primary" style={{ width: 64, height: 64 }} />
          </div>
        ) : (
          <>
            <Typography variant="h1" component="h1" className="page-title">
              {t('SavedQuotes')}
            </Typography>
            {this.renderSavedQuotesTable(state, t, configJSON)}
            <div className="title-section">
              <Typography variant="h1" component="h1" className="page-title">
                {t('ConfirmedQuotes')}
              </Typography>
              <FilterBy filterValue="CONFIRMED" onFilterValueChanged={() => { }} />
            </div>
            {this.renderConfirmedQuotesTable(state, t, configJSON)}
          </>
        )}
      </>
    );
  };
  renderSavedQuotesTable = (state: any, t: any, configJSON: any) => {
    const { savedQuotes } = state;
  
    // Helper function to filter saved quotes with non-empty order items
    const getFilteredData = (savedQuotes: any[]) => {
      return savedQuotes?.filter(
        (item: { attributes: { order_items: { data: string | any[] } } }) =>
          item.attributes.order_items.data.length !== 0
      );
    };
  
    // Helper function to construct the table row
    const buildTableRow = (item: any) => {
      return {
        cart_id: item.id,
        no_of_items: item.attributes.order_items.data.length,
        total_cost: (
          <div className="price">
            <span className="span">€</span>
            <span>{formatPrice(item.attributes.total_sum)}</span>
          </div>
        ),
        actions: renderActions(item, t),
      };
    };
  
    // Helper function to render actions for each row
    const renderActions = (item: any, t: any) => {
      return (
        <div style={{ display: "flex" }}>
          <CustomTooltip title={t("View")} style={{ marginTop: "-5px", marginBottom: "-5px" }}>
            <IconButton
              color="primary"
              className="iconBtn1"
              onClick={() =>
                this.navigateToQuoteDetails(
                  item.id,
                  "SAVED_QUOTE",
                  this.state.savedQuotesData,
                  localStorage.setItem("UPDATE", "DETAILS"),
                  localStorage.setItem(
                    "DEFAULT_TYPE",
                    item.attributes.delivery_type === "final_delivery"
                      ? "FINAL_DELIVERY_ONLY"
                      : "PARTIAL_DELIVERY"
                  ),
                  //@ts-ignore
                  item.attributes.address.data?.id,
                  item.attributes.alternate_address
                )
              }
            >
              <FileCopyOutlined />
            </IconButton>
          </CustomTooltip>
          <CustomTooltip title={t("Edit")} style={{ marginTop: "-5px", marginBottom: "-5px" }}>
            <IconButton
              color="primary"
              className="iconBtn2"
              test-id="navigateQuote"
              onClick={() =>
                this.navigateToQuoteDetails(
                  item.id,
                  "SAVED_QUOTE",
                  this.state.savedQuotesData,
                  localStorage.setItem("UPDATE", "UPDATE"),
                  localStorage.setItem(
                    "DEFAULT_TYPE",
                    item.attributes.delivery_type === "final_delivery"
                      ? "FINAL_DELIVERY_ONLY"
                      : "PARTIAL_DELIVERY"
                  ),
                  //@ts-ignore
                  item.attributes.address.data?.id,
                  item.attributes.alternate_address
                )
              }
            >
              <EditOutlined />
            </IconButton>
          </CustomTooltip>
          <CustomTooltip title={t("Deletebtn")} style={{ marginTop: "-5px", marginBottom: "-5px" }}>
            <IconButton color="default">
              <DeleteQuoteDialog onFormSubmit={() => this.deleteQuote(item.id)} t={t} />
            </IconButton>
          </CustomTooltip>
        </div>
      );
    };
  
    const filteredData = getFilteredData(savedQuotes);
  
    //the scroll pagination
    this.handleScroll = debounce(this.scrollTable, 300);
    if (filteredData && Array.isArray(filteredData) && filteredData.length > 0) {
      return (
        <div className="scrollable-wrapper" style={{ maxHeight: '65vh', overflowY: 'auto' }} onScroll={this.handleScroll}>
          <Table
            stickyHeader
            className="saved-quotes-table"
            titles={SavedQuotesTableName.map((table: any) => t(table.title))}
            items={filteredData.map(buildTableRow)}
          />
        </div>
      );
    } else if (this.state.savedQuotesError) {
      return t(configJSON.noQuotesFound);
    } else {
      return (
        <div className="loading-section" data-testid="orders-loading">
          <CircularProgress color="primary" style={{ width: 64, height: 64 }} />
        </div>
      );
    }
  };
  renderConfirmedQuotesTable = (state: any, t: any, configJSON: any) => {
    const { confirmedQuotes } = state;

    // Helper function to calculate days difference
    const getDaysDifference = (expirationDate: Date) => {
      const currentDate = new Date();
      const timeDifference = expirationDate.getTime() - currentDate.getTime();
      return Math.ceil(timeDifference / (24 * 60 * 60 * 1000));
    };

    // Helper function to format date and time
    const formatDateAndTime = (createdAt: string) => {
      const options = {
        day: '2-digit' as const,
        month: '2-digit' as const,
        year: 'numeric' as const,
        hour: '2-digit' as const,
        minute: '2-digit' as const,
        second: '2-digit' as const,
        hour12: true as const
      };
      const formattedDatetime = new Date(createdAt).toLocaleString('en-GB', options);
      const dateTimeParts = formattedDatetime.toUpperCase().replace(/,/g, '').split(' ');
      return { date: dateTimeParts[0], time: dateTimeParts[1] + ' ' + dateTimeParts[2] };
    };

    // Helper function to render actions for each row
    const renderActions = (item: any, t: any) => {
      const quotes_ = item?.quote||{};
      return (
        <div className="actions">
          <CustomTooltip title={t('View')} style={{ marginTop: '-5px', marginBottom: '-5px' }}>
            <IconButton
              className="navigateQuoteDetails"
              color="primary"
              onClick={() =>
                this.navigateToConffirmQuoteDetails(
                  quotes_.id,
                  'CONFIRMED_QUOTE',
                  this.state.confirmQuotesData,
                  localStorage.setItem('UPDATE', 'CONFIRM_VIEW'),
                  localStorage.setItem(
                    'DEFAULT_TYPE',
                    quotes_.single_delivery === 1 ? 'FINAL_DELIVERY_ONLY' : 'PARTIAL_DELIVERY'
                  ),
                  item.address?.id,
                  quotes_.alternate_address,
                  quotes_.total_sum
                )
              }
            >
              <FileCopyOutlined />
            </IconButton>
          </CustomTooltip>
          <CustomTooltip title={t('Copy Quotation')} style={{ marginTop: '-5px', marginBottom: '-5px' }}>
            <IconButton className="openPopupIconBtn" color="primary" onClick={() => this.openPopup(item)}>
              <FileCopyOutlined />
            </IconButton>
          </CustomTooltip>
        </div>
      );
    };
    this.handleScrollConfirmed = debounce(this.scrollConfirmedQuotesTable, 300);
    if (confirmedQuotes && Array.isArray(confirmedQuotes) && confirmedQuotes.length > 0) {
      return (
        <div className="confirmed-scrollable-wrapper" style={{ maxHeight: '65vh', overflowY: 'auto' }} onScroll={this.handleScrollConfirmed}>
        <Table
          stickyHeader
          className="confirmed-quotes-table"
          titles={ConfirmedQuotesTableName.map((table: any) => t(table.title))}
          items={confirmedQuotes.map((item: any) => {
            const quotes_  = item?.quote || {};
            const expirationDate = new Date(quotes_.expiration_date);
            const daysDifference = getDaysDifference(expirationDate);
            const { date, time } = formatDateAndTime(item?.quote?.quote_order_items[0]?.created_at);
            return {
              cart_id: quotes_.id,
              order_date: (
                <>
                  {date}
                  <br />
                  {time}
                </>
              ),
              no_of_items: quotes_.quote_order_items?.length,
              total_cost: (
                <div className="price">
                  <span className="span">€</span>
                  <span>{formatPrice(quotes_.total_sum)}</span>
                </div>
              ),
              to_ship: quotes_.to_ship,
              quote_id: quotes_.quote_code,
              status: quotes_.quote_status,
              expiry_date: daysDifference === 1 ? expirationDate.toLocaleDateString('en-GB') : t('Expired'),
              actions: renderActions(item, t),
            };
          })}
        />
      </div>
      );
    } else if (this.state.confirmedQuotesError) {
      return t(configJSON.noQuotesFound);
    } else {
      return (
        <div className="loading-section" data-testid="orders-loading">
          <CircularProgress color="primary" style={{ width: 64, height: 64 }} />
        </div>
      );
    }
  };
  // Customizable Area End







  render() {
    const { t } = this.props
    // Customizable Area Start
    const customer_id: string | null = localStorage.getItem('customer_id');
    const data = JSON.parse(localStorage.getItem(`cart_${customer_id}`) || "[]");
    // Customizable Area End  

    return (
      // Customizable Area Start
      <MainLayout
        id="quote-management"
        navigation={this.props.navigation}
      >
        {(() => {
          switch (true) {
            case this.state.view !== "LIST" && localStorage.getItem("UPDATE") === 'UPDATE':
              return (
                <>
                  {this.renderQuoteDetailsUpdate(this.state)}
                </>
              );
            case this.state.view !== "LIST" && localStorage.getItem("UPDATE") === 'DETAILS':
              return (
                <>
                  {this.renderQuoteDetailsView(this.state)}
                </>
              );
            case this.state.view !== "LIST" && localStorage.getItem("UPDATE") === 'CONFIRM_VIEW':
              return (
                <>
                  {this.renderConfirmQuoteDetailsView(this.state)}
                </>
              );
            default:
              return this.renderDefaultView(this.state, t, configJSON);
          }
        })()}
        <Dialog
          id="request-new-product"
          title={this.props.isRequestProductSubmitted ? t('Success') : t(configJSON.requestProductDialogRequestProductText)}
          ref={this.dialogRef}
          closable
          onClose={this.closeDialog}
        >

          <Typography variant="body2" className="description">
            You have {data.products ? Object.keys(data.products).length : 0} items in your cart. Do you want to remove these items from the cart?
          </Typography>

          <div className="Btn-Flex">
            <Button
              className="ButtonDialogYes rounded-button"
              variant="contained"
              color="primary"
              onClick={() => this.addProductToCart('YES')}
              fullWidth
            >
              Yes
            </Button>
            <Button
              className="ButtonDialogNo rounded-button"
              variant="contained"
              color="primary"
              onClick={() => this.addProductToCart('NO')}
              fullWidth
            >
              No
            </Button>
          </div>

        </Dialog>
      </MainLayout>
      // Customizable Area End


    );

  }
}
// Customizable Area Start
export default withTranslation()(Quotemanagement)
// Customizable Area End
