import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { FormikHelpers } from "formik";
import { imgPasswordInVisible } from "./assets";
import MergeEngineUtilities from "../../utilities/src/MergeEngineUtilities";
import { isValidEmail } from "../../../components/src/utilities/utils";
import { msgInfo } from "../../../components/src/constant";
import {
  logout,
  setCustomerId,
  setCustomerType,
  setProfileId,
} from "../../../components/src/utilities/StorageHelper";

interface IFormValues {
  email: string;
  password: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  formSubmitLoadingStatus: boolean;
  forgotPasswordFormSubmitLoading: boolean;
  forgotPasswordFormIsSubmitted: boolean;
  isFormError: boolean;
  isEmailError: boolean;
  isPasswordError: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  forgotPasswordCallId: string = "";
  fetchProfileApiCall: string = "";
  loginApiCall: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      formSubmitLoadingStatus: false,
      forgotPasswordFormSubmitLoading: false,
      forgotPasswordFormIsSubmitted: false,
      isFormError: false,
      isEmailError: false,
      isPasswordError: false,
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.redirectToLandingPage = this.redirectToLandingPage.bind(this);
    this.forgotPasswordDialogClosed =
      this.forgotPasswordDialogClosed.bind(this);
    this.onForgotPasswordFormSubmit =
      this.onForgotPasswordFormSubmit.bind(this);
    this.onLoginFormInputBlur = this.onLoginFormInputBlur.bind(this);
    this.callGetValidationApi = this.callGetValidationApi.bind(this);

    this.handleEmailLoginResponse = this.handleEmailLoginResponse.bind(this);
    this.handleForgotPasswordResponse =
      this.handleForgotPasswordResponse.bind(this);
    this.handleValidationResponse = this.handleValidationResponse.bind(this);
    this.handleFetchProfileResponse =
      this.handleFetchProfileResponse.bind(this);
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  onFormSubmit(values: IFormValues, actions: FormikHelpers<IFormValues>) {
    const isEmailValid = isValidEmail(values.email);
    if (!isEmailValid || values.password.length <= 0) {
      return actions.setErrors({
        email: configJSON.emailErrorMessage,
        password: msgInfo.passwordErrorMessage,
      });
    }

    actions.setSubmitting(true);

    this.setState({ formSubmitLoadingStatus: true }, () => {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiEmailLoginCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.loginApiMethod}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethod
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(configJSON.apiHeader)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          data: {
            type: "email_account",
            attributes: {
              email: values.email,
              password: values.password,
            },
          },
        })
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    });
  }

  onForgotPasswordFormSubmit(email: string) {
    this.setState(
      {
        forgotPasswordFormSubmitLoading: true,
      },
      () => {
        const Url = "https://" + window.location.host;

        const requestMessage = MergeEngineUtilities.createRequestMessage({
          endpoint: `${configJSON.loginForgotPasswordApiMethod}`,
          method: configJSON.apiMethod,
          header: {},
          data: {
            data: { attributes: { email } },
            url: Url,
          },
          useToken: true,
        });

        this.forgotPasswordCallId = requestMessage.messageId;

        runEngine.sendMessage(requestMessage.id, requestMessage);
      });
  }

  forgotPasswordDialogClosed() {
    this.setState({
      forgotPasswordFormIsSubmitted: false,
      forgotPasswordFormSubmitLoading: false,
    });
  }

  redirectToLandingPage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
    this.send(msg);
  }

  onLoginFormInputBlur() {
    this.setState({ isFormError: false })
  }

  fetchProfile() {
    const profile_id = MergeEngineUtilities._profile_id;

    // Fetching profile
    const requestMessage = MergeEngineUtilities.createRequestMessage({
      endpoint: `${configJSON.fetchProfileMethod}/${profile_id}`,
      method: configJSON.getApiMethod,
      header: {},
      useToken: true,
    })

    this.fetchProfileApiCall = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleEmailLoginResponse(responseJson: any, apiRequestCallId: string) {
    if (apiRequestCallId !== this.apiEmailLoginCallId) {
      return;
    }

    if (responseJson && responseJson.meta && responseJson.meta.token) {
      this.saveLoggedInUserData(responseJson);
      setCustomerId(responseJson.meta.id);
      setProfileId(responseJson.meta.customer_code);
      MergeEngineUtilities._token = responseJson.meta.token;
      MergeEngineUtilities._customer_id = responseJson.meta.id;
      MergeEngineUtilities._profile_id = responseJson.meta.customer_code;
      this.fetchProfile();
    } else {
      //Check Error Response
      //this.parseApiErrorResponse(responseJson);
      this.setState({ isFormError: true, formSubmitLoadingStatus: false });
      if (responseJson.errors[0].failed_login === "Login Failed") {
        this.setState({
          isEmailError: false,
          isPasswordError: true,
          formSubmitLoadingStatus: false,
        });
      }
      if (
        responseJson.errors[0].failed_login ===
        "Account not found, or not activated"
      ) {
        this.setState({
          isEmailError: true,
          isPasswordError: false,
          formSubmitLoadingStatus: false,
        });
      }
    }
  }

  handleValidationResponse(responseJson: any, apiRequestCallId: string) {
    if (apiRequestCallId !== this.validationApiCallId) { return; }

    if (
      responseJson
      && Array.isArray(responseJson.messages)
      && responseJson.messages[0]
      && responseJson.messages[0].Token
    ) {
      this.redirectToLandingPage();
    } else {
      logout();
    }
  }

  handleForgotPasswordResponse(responseJson: any, apiRequestCallId: string) {
    if (apiRequestCallId !== this.forgotPasswordCallId) { return; }

    this.setState({
      forgotPasswordFormSubmitLoading: false
    }, () => {
      if (!Array.isArray(responseJson?.errors)) {
        this.setState({
          forgotPasswordFormIsSubmitted: true
        });
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
    });
  }

  handleFetchProfileResponse(responseJson: any, apiRequestCallId: string) {
    if (apiRequestCallId !== this.fetchProfileApiCall) {
      return;
    }

    if (responseJson && responseJson.data) {
      setCustomerType(responseJson.data.attributes.customer_type);
      MergeEngineUtilities._customer_type =
        responseJson.data.attributes.customer_type;

      this.redirectToLandingPage();
    } else {
      this.setState({ isFormError: true, formSubmitLoadingStatus: false });
    }
  }

  habdleAPIResponse = (apiRequestCallId: string, responseJson: any) => {
    if (
      apiRequestCallId === this.validationApiCallId &&
      responseJson !== undefined
    ) {
      let arrayholder = responseJson.data;

      if (arrayholder && arrayholder.length !== 0) {
        let regexData = arrayholder[0];

        if (regexData && regexData.email_validation_regexp) {
          this.emailReg = new RegExp(regexData.email_validation_regexp);
        }
      }
    }
  }


  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
        this.habdleAPIResponse(apiRequestCallId, responseJson);
        this.handleEmailLoginResponse(responseJson, apiRequestCallId);
        this.handleForgotPasswordResponse(responseJson, apiRequestCallId);
        this.handleValidationResponse(responseJson, apiRequestCallId);
        this.handleFetchProfileResponse(responseJson, apiRequestCallId);

        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    // Merge Engine - Navigation - btnEmailLogIn - Start
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    // Merge Engine - Navigation - btnEmailLogIn - End
  }

  goToForgotPassword() {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  async callGetValidationApi() {
    this.validationApiCallId = await MergeEngineUtilities.validateToken();
  }
}
