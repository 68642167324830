import React, { useCallback, useEffect, useRef, useState } from "react";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { useRunEngine } from "../../utilities/src/hooks/useRunEngine";
import { useBlockHelpers } from "../../utilities/src/hooks/useBlockHelpers";

import OrderManagementView from "./OrderManagementView";

// Customizable Area Start
import { FILTER_VALUE, INNER_FILTER_VALUE, Order, OrderListItem, SORT_VALUE } from "./types";
import { insertParam, addHashToUrl, formatPrice, safeJSONparse, getQueryParams, removeQueryParam, mergeAddress, debounce } from "../../../components/src/utilities/utils";
import MergeEngineUtilities from "../../utilities/src/MergeEngineUtilities";
import { IAddress } from "../../../components/src/types/types";

interface IFilterObj {
  filtering: { type: FILTER_VALUE | null, value?: INNER_FILTER_VALUE },
  sorting: { type: SORT_VALUE | null },
  search: { value: string }
}
// Customizable Area End

export const configJSON = require("./config");

export interface ViewProps {
  testID: string;
  // Customizable Area Start
  view: 'LIST' | 'DETAILS',
  orders: OrderListItem[] | null;
  activeOrder: Order;
  loading: boolean;
  navigation: any;
  filterValue: IFilterObj['filtering']['type'];
  innerFilterValue?: IFilterObj['filtering']['value'];
  sortValue: IFilterObj['sorting']['type'];
  searchValue: IFilterObj['search']['value'];
  shippingAddresses: (IAddress & { id: number })[]
  onSearchFormSubmit: (event: React.FormEvent<HTMLFormElement>) => void,
  navigateToOrderDetail: (order_id: OrderListItem['id']) => void,
  onSortValueChanged: (value: IFilterObj['sorting']['type']) => void,
  onFilterValueChanged: (value: IFilterObj['filtering']['type'], innerParamValue?: IFilterObj['filtering']['value']) => void,
  onSearchValueChanged: (value: string) => void,
  shippingAlternateAddresses : (IAddress & { id: number })[]
  ScrollTableOrders : () => void
  handleScroll : () => void
  t?:any
  // Customizable Area End
}

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

const subscribedMessages = [
  // Customizable Area Start
  MessageEnum.RestAPIResponceMessage,
  MessageEnum.SessionResponseMessage,
  MessageEnum.NavigationPayLoadMessage,
  // Customizable Area End
];

const OrderManagement: React.FC<Props> = (props) => {
  // Customizable Area Start
  const [view, setView] = useState<ViewProps['view']>('LIST');
  const [orders, setOrders] = useState<OrderListItem[]>([]);
  const [hasMoreOrders , setHasMoreOrders] = useState(true) ;
  const [page, setPage] = useState(1);
  const [activeOrder, setActiveOrder] = useState<Order>({} as Order);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchValue, setSearchValue] = useState<string>('');
  const [shippingAddresses, setShippingAddresses] = useState<ViewProps['shippingAddresses']>([]);
  const [shippingAlternateAddresses, setShippingAlternateAddresses] = useState<ViewProps['shippingAddresses']>([]);
  const [filters, setFilters] = useState<IFilterObj>({
    filtering: { type: null },
    sorting: { type: 'ALL_ORDERS' },
    search: { value: '' }
  });
  const validationApiCallId = useRef<string>()
  const getOrdersCallId = useRef<string>('')
  const fetchLanguageTextsCallId = useRef<string>('')
  const getOrderDetailsCallId = useRef<string>('')
  const fetchAddressesCallId = useRef<string>('')
  const isOrdersInitialized = useRef<boolean>(false)
  const getAlternateAddressCallId =useRef<string>('')
  // Customizable Area End

  useEffect(() => {
    setReceiveCallback(receive);

    subscribedMessages.forEach((message) => subscribe(message));

    // Customizable Area Start

    // Handle authentication
    if (MergeEngineUtilities.isLoggedIn()) {
      initializeOrders();
    } else {
      MergeEngineUtilities.validateToken(props)
        .then((callId: string) => validationApiCallId.current = callId);
    }
    fetchLanguageTextsCallId.current = MergeEngineUtilities.fetchLanguageTexts();
    // Customizable Area End

    return () => {
      subscribedMessages.forEach((message) => unsubscribeFromMessage(message));
    };
  }, []);

  const receive = (from: string, message: Message) => {
    // Customizable Area Start
    debugLog("API Message Received", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      handleValidationResponse(responseJson, apiRequestCallId);
      handleOrdersResponse(responseJson, apiRequestCallId);
      handleOrderDetailsResponse(responseJson, apiRequestCallId);
      handleAddressesResponse(responseJson, apiRequestCallId);
      handleAlternateAddressesResponse(responseJson,apiRequestCallId)


      if (fetchLanguageTextsCallId.current === apiRequestCallId) {
        MergeEngineUtilities.handleLanguageResponse(responseJson);
      }

      parseApiCatchErrorResponse(errorReponse);
    } else if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      const data = message.getData(getName(MessageEnum.NavigationData));
      if (data && data.navigateFrom === 'sidebar') {
        setView('LIST');
        setActiveOrder({} as Order);

        setSearchValue('');
        setFilters((current) => {
          if (!isFilterExistsInState(current)) {
            fetchOrders();
          }

          return {
            sorting: { type: 'ALL_ORDERS' },
            filtering: { type: null, value: undefined },
            search: { value: '' }
          }
        });
      }

      if (data && data.order_id) {
        addHashToUrl(data.order_id);
        setView('DETAILS');
        fetchOrderDetail(data.order_id);
      }
    }
    // Customizable Area End
  };

  // Customizable Area Start
  const {
    sendNetworkRequest,
    setReceiveCallback,
    subscribe,
    debugLog,
    unsubscribeFromMessage,
  } = useRunEngine();

  const { parseApiCatchErrorResponse, parseApiErrorResponse } = useBlockHelpers();

  useEffect(() => {
    function hashListener() {
      const isHashExist = !!window.location.hash;
     
      if (!isHashExist) {
        setView('LIST');
        setActiveOrder({} as Order);

        if (!isFilterExistsInState() && isFilterExistsInUrl()) {
          setFiltersFromUrl();
        } else {
          fetchOrders();
        }

        return;
      }

      setView('DETAILS')
      const hashValue = window.location.hash.substring(1);
      fetchOrderDetail(hashValue);
    }

    if (window) { window.addEventListener('hashchange', hashListener, true); }

    return () => {
      if (window) { window.removeEventListener('hashchange', hashListener, true); }
    }
  }, [filters.filtering.value, filters.search.value, filters.sorting.type, filters.filtering.type]);

  useEffect(() => {
    if (isOrdersInitialized.current === true) {
      fetchOrders(page);
    }
  }, [filters.filtering.type, filters.filtering.value, filters.sorting.type, filters.search.value , page]);

  const handleValidationResponse = (responseJson: any, apiRequestCallId: string) => {
    if (apiRequestCallId === validationApiCallId.current) {
      if (responseJson && Array.isArray(responseJson.messages) && responseJson.messages[0] && responseJson.messages[0].Token) {
        initializeOrders()
      } else {
        MergeEngineUtilities.logout(props);
      }
    }
  }

  const handleOrdersResponse = (responseJson: any, apiRequestCallId: string) => {
    if (apiRequestCallId !== getOrdersCallId.current) {
      return;
    }
  
    setLoading(false);
    const hasFilters = isFilterExistsInState() || isFilterExistsInUrl();
    if (responseJson && responseJson.data?.length > 0) {
      const newOrders: OrderListItem[] = responseJson.data.map((item: any) => {
        let address = '';
  
        if (item.attributes.alternate_address) {
          address = item.attributes.alternate_address;
        } else if (item.attributes.address.data) {
          address = mergeAddress(item.attributes.address.data.attributes);
        }
  
        return {
          id: item.id,
          customer_id: item.attributes.customer.data.attributes.customer_code,
          customer_fullname: `${item.attributes.customer.data.attributes.first_name}`,
          customer_address: address,
          order_date: new Date(item.attributes.created_at).toLocaleDateString('en-GB'),
          total_amount: formatPrice(item.attributes.total_sum),
          status: item.attributes.status
        };
      });
  
      if (hasFilters) {
        setOrders(newOrders); 
      } else {
        // Append new unique orders to existing orders
        setOrders(prevOrders => {
          const orderSet = new Set(prevOrders.map(order => order.id));
          const uniqueOrders = newOrders.filter(order => !orderSet.has(order.id));
          return [...prevOrders, ...uniqueOrders];
        });
      }
    } else {
      setHasMoreOrders(false); 
    }
  }


  const handleOrderDetailsResponse = (responseJson: any, apiRequestCallId: string) => {
    if (apiRequestCallId !== getOrderDetailsCallId.current) { return; }

    setLoading(false);

    if (responseJson && responseJson.data) {
      let customer = {} as any;
      let address = {} as any;

      if (!!responseJson.data.attributes.customer.data) {
        customer = { ...responseJson.data.attributes.customer.data.attributes, id: responseJson.data.attributes.customer.data.attributes.customer_code }
      }

      if (!!responseJson.data.attributes.address.data) {
        address = responseJson.data.attributes.address.data.attributes;
      }

      setActiveOrder({
        id: responseJson.data.id,
        status: responseJson.data.attributes.status,
        alternate_address: responseJson.data.attributes.alternate_address,
        address,
        customer,
        order_date: new Date(responseJson.data.attributes.created_at).toLocaleDateString('en-GB'),
        total_amount: responseJson.data.attributes.total_sum,
        total_items: responseJson.data.attributes.total_items,
        total_tax: responseJson.data.attributes.total_tax,
        order_items: responseJson.data.attributes.order_items.data.map((item: any) => {
          return {
            ...item?.attributes?.catalogue?.data?.attributes,
            id: item?.id,
            quantity: item?.attributes?.quantity,
            price: formatPrice(item?.attributes?.price),
            net_amount: formatPrice(item?.attributes?.price * item?.attributes?.quantity),
            static_product: item.attributes.static_product_name,
            static_unit: item.attributes.static_base_unit,
            static_product_description:item.attributes.static_product_description
          }
        })
      })
    } else {
      parseApiErrorResponse(responseJson);
    }
  }

  const handleAddressesResponse = (responseJson: any, apiRequestCallId: string) => {
    if (apiRequestCallId !== fetchAddressesCallId.current) { return; }

    if (responseJson && Array.isArray(responseJson.addresses)) {
      setShippingAddresses(responseJson.addresses);
    }
  }
  const removeDuplicatesByAlternateAddress=(arr: any[]) => {
    const seenAddresses = new Set();
    return arr.filter((item) => {
      if (seenAddresses.has(item.alternate_address)) {
        return false;
      } else {
        seenAddresses.add(item.alternate_address);
        return true;
      }
    });
  }

  const handleAlternateAddressesResponse = (responseJson: any, apiRequestCallId: string) => {
    if (apiRequestCallId !== getAlternateAddressCallId.current) { return; }
    if (responseJson && !responseJson.error) {
      const newArray = removeDuplicatesByAlternateAddress(responseJson.alternate_addresses)
      setShippingAlternateAddresses(newArray);
    }
  }
  const ScrollTableOrders = () => {
    const el = document.querySelector('.scrollable-order-table'); 
    if (!el || !hasMoreOrders) {
      return;
    }
    if (el.scrollHeight - el.scrollTop <= el.clientHeight + 75 && orders.length>=10 ) {
      setPage(prevPage => prevPage + 1);
    }
  }
   const handleScroll = debounce(ScrollTableOrders,300)
  

  const initializeOrders = () => {
    isOrdersInitialized.current = true;

    if (window && window.location.hash.length > 0) {
      setView('DETAILS');
      fetchOrderDetail(window.location.hash.substring(1));
    } else if (window && window.location.search.length > 0) {
      setFiltersFromUrl();
    } else {
      fetchOrders();
    }

    fetchDeliveryAddresses();
    fetchAlternatAddress();
  }

  const fetchOrderDetail = (order_id: string) => {
    // Temporarily solution
    setLoading(true);
    sendNetworkRequest(
      getOrderDetailsCallId,
      configJSON.orderDetailsApiHttpMethod,
      `${configJSON.orderDetailsApiMethod}/${order_id}`,
      { ...configJSON.apiHeader, token: MergeEngineUtilities._token }
    );
  }
  const fetchAlternatAddress = () => {
    // Temporarily solution
    setLoading(true);

    sendNetworkRequest(
      getAlternateAddressCallId,
      configJSON.fetchAddressesApiHttpMethod,
      `${configJSON.fetchAlternateAddresses}`,
      { ...configJSON.apiHeader, token: MergeEngineUtilities._token }
    );
  }

  const fetchDeliveryAddresses = () => {
    sendNetworkRequest(
      fetchAddressesCallId,
      configJSON.fetchAddressesApiHttpMethod,
      `${configJSON.fetchAddressesMethod}?customer_code=${MergeEngineUtilities._profile_id}`,
      { ...configJSON.apiHeader, token: MergeEngineUtilities._token }
    );
  }

  const fetchOrders = (page = 1) => {
    const hasFilters = isFilterExistsInState() || isFilterExistsInUrl();
    let apiUrl = hasFilters 
      ? `${configJSON.orderFiltersApiMethod}` 
      : `${configJSON.orderApiMethod}`;
    
    const filtersArr : any = [];
  
    if (hasFilters) {
      buildFilterQuery(filtersArr);
      buildSortingQuery(filtersArr);
      buildSearchQuery(filtersArr);
  
      apiUrl += `?${filtersArr.join('&')}`;
    } else {
      apiUrl += `?page=${page}`;
    }
  
    sendNetworkRequest(
      getOrdersCallId,
      configJSON.orderFiltersApiHttpMethod,
      apiUrl,
      { ...configJSON.apiHeader, token: MergeEngineUtilities._token }
    );
  };
  
  const buildFilterQuery = (filtersArr : any) => {
    const innerValues : any = safeJSONparse(filters.filtering.value!, filters.filtering.value || {})
    if (!filters.filtering.type) return;
  
    let query = '';
    switch (filters.filtering.type) {
      case 'CUSTOM_DATES':
        if (innerValues && (innerValues.startDate || innerValues.endDate)) {
          query = `start_date=${new Date(innerValues.startDate).toISOString()}&end_date=${new Date(innerValues.endDate).toISOString()}`;
        }
        break;
      case 'ORDER_STATUS':
        query = `status=${filters.filtering.value}`;
        break;
      case 'DELIVERY_ADDRES':
        query = `address_id=${filters.filtering.value}`;
        break;
      case 'ALTERNATE_ADDRESS':
        query = `alternate_address=${filters.filtering.value}`;
        break;
      default:
        if (filters.filtering.type !== null) {
          query = `${filters.filtering.type}=true`;
        }
    }
    filtersArr.push(query);
  };
  
  const buildSortingQuery = (filtersArr :any) => {
    if (filters.sorting.type && filters.sorting.type !== 'ALL_ORDERS') {
      const sortValue = filters.sorting.type === 'VIEW_LAST_MONTH' ? 'last_month' : 'last_week';
      filtersArr.push(`sort=${sortValue}`);
    }
  };
  
  const buildSearchQuery = (filtersArr : any) => {
    if (filters.search.value) {
      filtersArr.push(`query=${filters.search.value}`);
    }
  };
  const onSearchFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (searchValue) {
      insertParam('search', searchValue);
    } else {
      removeQueryParam('search');
    }

    setFilters((prev) => ({ ...prev, search: { value: searchValue } }))
  }

  const navigateToOrderDetail = (order_id: OrderListItem['id']) => {
    setView('DETAILS');
    addHashToUrl(`${order_id}`);
  }

  const onSortValueChanged: ViewProps['onSortValueChanged'] = (value) => {
    insertParam('sort', value);
    setFilters((prev) => ({ ...prev, sorting: { type: value }}));
  }

  const onFilterValueChanged: ViewProps['onFilterValueChanged'] = (value, innerParamValue) => {
    if (innerParamValue) {
      let v = null;
      if (typeof innerParamValue === 'object') {
        v = JSON.stringify(innerParamValue);
      } else {
        v = innerParamValue;
      }

      insertParam('filter_inner_value', encodeURIComponent(v));
    } else {
      removeQueryParam('filter_inner_value');
    }

    if (value === null) {
      removeQueryParam('filter');
    } else {
      insertParam('filter', value);
    }

    setFilters((prev) => ({ ...prev, filtering: { type: value, value: innerParamValue }}));
  }

  const onSearchValueChanged: ViewProps['onSearchValueChanged'] = (value) => {
    setSearchValue(value);
  }

  const isFilterExistsInState = useCallback((argFilters?: IFilterObj) => {
    const f = argFilters || filters;
    return (
      f.filtering.type ||
      f.search.value ||
      (f.sorting.type && f.sorting.type !== 'ALL_ORDERS')
    )
  }, [filters.filtering.value, filters.search.value, filters.sorting.type, filters.filtering.type]);


  const isFilterExistsInUrl = () => {
    const queryParams = getQueryParams() as any[];
    return queryParams.some((item: any) => ['sort', 'filter', 'search'].includes(item.key));
  }

  const setFiltersFromUrl = () => {
    const queryParams = getQueryParams() as any;
    const query: IFilterObj = {
      filtering: { type: null },
      sorting: { type: 'ALL_ORDERS' },
      search: { value: '' }
    };

    queryParams.forEach((item: any) => {
      switch (item.key) {
        case 'sort':
          query.sorting = { type: item.value };
          break;
        case 'filter':
          query.filtering.type = item.value;
          break;
        case 'filter_inner_value':
          query.filtering.value = item.value;
          break;
        case 'search':
          query.search = { value: item.value };
          break;
      }
    });

    setFilters((prev => ({ ...prev, ...query })));
  }

  // Customizable Area End

  const viewProps: ViewProps = {
    testID: "OrderManagementView",
    // Customizable Area Start
    view,
    orders,
    activeOrder,
    loading,
    navigation: props.navigation,
    searchValue: searchValue || filters.search.value,
    onSearchFormSubmit,
    navigateToOrderDetail,
    onSortValueChanged,
    onFilterValueChanged,
    onSearchValueChanged,
    filterValue: filters.filtering.type,
    innerFilterValue: filters.filtering.value,
    sortValue: filters.sorting.type,
    shippingAddresses,
    shippingAlternateAddresses, 
    ScrollTableOrders , 
    handleScroll
    // Customizable Area End
  };

  return <OrderManagementView {...viewProps} />;
};

export default OrderManagement;
