import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import MainLayout from "../../../components/src/MainLayout.web";
import './bestseller.css';
// Customizable Area End

import CfbestsellerfeatureController, {
  Props,
  configJSON,
} from "./CfbestsellerfeatureController";
import BestSellerProductsTable from "./BestSellerProductsTable.web";
import { withTranslation } from "react-i18next";

export class Cfbestsellerfeature extends CfbestsellerfeatureController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { t }= this.props
    return (
      // Customizable Area Start
      <MainLayout
        id="bestseller"
        navigation={this.props.navigation}
        cartCount={Object.keys(this.state.cart.products).length}
      >
        <div className="table-title-section">
          <Typography component="h3" variant="h1">{t(configJSON.title)}</Typography>
          <span>{t(configJSON.resultText)} ( {this.state.bestSellers.length} )</span>
        </div>

        {this.state.loading === 'INIT' ? (
          <div className="loading-section">
            <CircularProgress
                color="primary"
                style={{ width: 64, height: 64 }}
            />
          </div>
        ) : (
          <>
            {this.state.bestSellers.length > 0 ? (
              <BestSellerProductsTable
                products={this.state.bestSellers}
                cart={this.state.cart}
                // @ts-ignore
                onCartItemUpdate={this.updateProductQuantityFromCart}
                fetchProducts={this.fetchBestSellers}
                isNewProductsLoading={this.state.loading === 'NEW_PRODUCTS'}
              />
            ) : (
              <span className="no-products-found">{t(configJSON.noItemsFound)}</span>
            )}
          </>
        )}
      </MainLayout>
      // Customizable Area End
    );
  }
}

export default withTranslation()(Cfbestsellerfeature)
// Customizable Area End
// Customizable Area Start
// Customizable Area End
