import React from "react";

import {
  // Customizable Area Start
  Button,
  Divider,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { withTranslation } from "react-i18next";
import i18n from "../../../components/src/i18n";
// Customizable Area End

export class Languageswitch extends  React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  changeLanguageTab = (lang: any) => {
    localStorage.setItem("language", lang); 
    // @ts-ignore
    i18n.changeLanguage(lang);   
  };
  // Customizable Area End

  render() {
    const lang = localStorage.getItem("language");
    return (
      // Customizable Area Start
      <>
        <div style={{ display: "flex" }}>
          <Button
            data-test-id="btnEn"
            variant="text"
            //@ts-ignore
            color={lang === "en" ? "primary" : ""}
            onClick={() => this.changeLanguageTab("en")}
          >
            ENG
          </Button>
          <Divider
            orientation="vertical"
            style={{ marginTop: "7px" }}
            className="divider"
          />
          <Button
           data-test-id="btnIt"
            //@ts-ignore
            color={lang === "it" ? "primary" : ""}
            variant="text"
            onClick={() => this.changeLanguageTab("it")}
          >
            ITA
          </Button>
        </div>
      </>
      // Customizable Area End
    );
  }
}
export default withTranslation()(Languageswitch);

// Customizable Area Start
interface IProps { 
  t?:any
}

interface IState {}
// Customizable Area End
