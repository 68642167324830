import * as React from "react";
// Customizable Area Start
import {
  Container,
  Button,
  Typography,
  Box,
  Grid,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
// Customizable Area End

import { configJSON, ViewProps } from "./OrderDetails";

const OrderDetailsView: React.FC<ViewProps> = ({
  // Customizable Area Start
  order,
  deleteOrderItem,
  couponCode,
  setCouponCode,
  applyCouponCode,
  navigateToAddress,
  loading,
  handledeleteOrder,
  handleCancelOrder,
  // Customizable Area End
}) => {

  // Customizable Area Start
  // Customizable Area End

  return (
    // Customizable Area Start
    <ThemeProvider theme={theme}>
      <Container style={webStyle.mainWrapper}>
        <Grid container spacing={3}>
          {!order || loading ? (
            <Grid item xs={12}>
              <Typography data-testid="loading" style={webStyle.loadingText}>
                {configJSON.loadingText}
              </Typography>
            </Grid>
          ) : (
            <>
              <Grid item lg={8} md={8} sm={12} xs={12}>
                <Box style={webStyle.orderCard}>
                  <Box style={webStyle.orderCardTitle}>
                    <Typography data-testid="orderNumber" style={webStyle.orderNumber}>
                      {configJSON.orderNumberText}:{" "}
                    </Typography>
                    <Typography style={webStyle.orderStatus}>
                    </Typography>
                  </Box>
                </Box>

                <Box style={webStyle.orderCard}>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <Box style={webStyle.orderCard}>
                  <Box style={webStyle.totalTextWrapper}>
                    <Typography style={webStyle.totalTextLabel}>
                      {configJSON.subTotalText}
                    </Typography>
                    <Typography style={webStyle.totalTextPrice}>
                      {configJSON.currencySymbole} 
                    </Typography>
                  </Box>
                  <Box style={webStyle.totalTextWrapper}>
                    <Typography style={webStyle.totalTextLabel}>
                      {configJSON.shippingChargeText}
                    </Typography>
                    <Typography style={webStyle.totalTextPrice}>
                      {configJSON.currencySymbole}{" "}
                    </Typography>
                  </Box>
                  <Box style={webStyle.totalTextWrapper}>
                    <Typography style={webStyle.totalTextLabel}>
                      {configJSON.totalTaxText}
                    </Typography>
                    <Typography style={webStyle.totalTextPrice}>
                      {configJSON.currencySymbole}
                    </Typography>
                  </Box>
                  <Box style={webStyle.totalTextWrapper}>
                    <Typography style={webStyle.totalTextLabel}>
                      {configJSON.discountText}
                    </Typography>
                    <Typography style={webStyle.totalTextPrice}>
                      - {configJSON.currencySymbole}{" "}
                    </Typography>
                  </Box>
                  <Box style={webStyle.grandTotalTextWrapper}>
                    <Typography style={webStyle.totalTextLabel}>
                      {configJSON.totalText}
                    </Typography>
                    <Typography style={webStyle.totalTextPrice}>
                      {configJSON.currencySymbole} 
                    </Typography>
                  </Box>
                </Box>
                <Button
                  color="primary"
                  onClick={handledeleteOrder}
                  style={webStyle.cancelButton}
                  fullWidth
                >
                  {configJSON.deleteOrderText}
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </ThemeProvider>
    // Customizable Area End
  );
};

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#6200ee",
      contrastText: "#fff",
    },
  },
});

const webStyle = {
  mainWrapper: {
    paddingBottom: 32,
    background: "#fff",
  },
  loadingText: {
    textAlign: "center" as "center",
    marginTop: 20,
  },
  orderCard: {
    border: "1px solid #ccc",
    borderRadius: 5,
    marginTop: 25,
    padding: 20,
  },
  orderCardTitle: {
    display: "flex",
    justifyContent: "space-between",
  },
  orderNumber: {
    fontWeight: 700,
  },
  orderStatus: {
    fontWeight: 700,
  },
  orderItem: {
    display: "flex",
    flexDirection: "column" as "column",
    padding: 12,
    border: "1px solid #ccc",
    borderRadius: 5,
    marginBottom: 20,
    marginTop: 20,
  },
  orderItemName: {
    fontWeight: 700,
    flex: 1,
  },
  orderItemPrice: {
    fontWeight: 700,
  },
  namePriceWrapper: {
    display: "flex",
    width: "100%",
    marginBottom: 5,
  },
  deleteBtnStyle: {
    textTransform: "capitalize" as "capitalize",
    marginTop: 10,
  },
  couponCodeWrapper: {
    display: "flex",
  },
  couponCodeInputStyle: {
    flex: 1,
  },
  totalTextWrapper: {
    display: "flex",
    marginBottom: 5,
  },
  totalTextLabel: {
    flex: 1,
  },
  totalTextPrice: {
    fontWeight: 700,
  },
  grandTotalTextWrapper: {
    borderTop: "1px solid #ccc",
    display: "flex",
    paddingTop: 10,
    marginTop: 10,
  },
  cancelButton: {
    marginTop: 20,
    color: "#ff0000",
    fontWeight: 700,
  },
  addressTitle: {
    fontWeight: 700,
  },
  selectAddressBtn: {
    marginTop: 15,
  }
};
// Customizable Area End

export default OrderDetailsView;
