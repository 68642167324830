Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.apiContentType = 'application/json'
exports.getApiMethod = 'GET'
exports.postApiMethod = 'POST'
exports.deleteApiMethod = 'DELETE'
exports.putApiMethod = 'PUT'

exports.getOrdersApiEndPoint = 'shopping_cart/orders'
exports.createOrderItemApiEndPoint = 'shopping_cart/order_items'
exports.deleteOrderItemApiEndPoint = 'shopping_cart/orders'

exports.errorTitle = 'Error'
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.'

exports.deleteDialogText = 'DeleteDialogText'
exports.requestProductApiEndPoint = "bx_block_address/request_addresses";

exports.createOrderApiMethod = 'bx_block_shopping_cart/order_items';
exports.fetchAddressesMethod = 'bx_block_address/addresses';

exports.fetchShoppingCartApiEndpoint = 'bx_block_shopping_cart/order_items';
exports.submitOffersApiEndpoint = 'bx_block_order_management/quote_orders';

exports.noCartFound = "NoCartFound"
exports.viewOrderText = 'ViewOrder';

exports.needPartialDeliveryText = 'NeedPartialDeliveryText';
exports.needFinalDeliveryText = 'NeedFinalDeliveryText';
exports.toBeShippedText = 'ToBeShipped';
exports.submitOrderButtonText = 'SubmitOrder';
exports.placeOrderButtonText = 'PlaceOrder';
exports.saveQuotes ='SavedQuotes';
exports.cartPageTitle = 'Cart';
exports.checkoutPageTitle = 'Checkout';
exports.viewQuoteText = 'ViewQuoteText';
exports.submitOffer = 'SubmitOffers'
exports.submitOuote = "SubmitQuote";
// Customizable Area End
