import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isVisible: boolean;
  token: string;
  data: any;
  priceLowtoHigh: boolean;
  priceHightoLow: boolean;
  newestProduct: boolean;
  popular: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SortingController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
  getSortAscApiCallId: any;
  getSortDescApiCallId: any;
  getsortNewApiCallId: any;
  getPopularityApiCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      isVisible: false,
      token: "",
      data: "",
      priceLowtoHigh: false,
      priceHightoLow: false,
      newestProduct: false,
      popular: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);

      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
      this.getListRequest(token);
    }
    
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      // Switch Here 

      switch (apiRequestCallId) {
        case this.getProductApiCallId:
          this.getProductApiCallIdResponse(responseJson, errorResponse)
          break;
        case this.getSortAscApiCallId:
          this.getSortAscApiCallIdResponse(responseJson, errorResponse)
          break;
        case this.getSortDescApiCallId:
          this.getSortDescApiCallIdResponse(responseJson, errorResponse)
          break;
        case this.getsortNewApiCallId:
          this.getsortNewApiCallIdResponse(responseJson, errorResponse)
          break;
        case this.getPopularityApiCallId:
          this.getPopularityApiCallIdResponse(responseJson, errorResponse)
          break;
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  
  getProductApiCallIdResponse =  (responseJson : any, errorResponse : any) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({
        data: responseJson.data
      });
    } else {
      this.parseApiErrorResponse(responseJson);
      this.parseApiCatchErrorResponse(errorResponse);
    }
  }

  getSortAscApiCallIdResponse =  (responseJson : any, errorResponse : any) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({
        data: responseJson.data,
        priceLowtoHigh: true,
        priceHightoLow: false,
        newestProduct: false,
        popular: false
      });
    } else {
      this.parseApiCatchErrorResponse(errorResponse);
    }
  }

  getSortDescApiCallIdResponse =  (responseJson : any, errorResponse : any) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({
        data: responseJson.data,
        priceLowtoHigh: false,
        priceHightoLow: true,
        newestProduct: false,
        popular: false
      });
    } else {
      this.parseApiCatchErrorResponse(errorResponse);
    }
  }

  getsortNewApiCallIdResponse =  (responseJson : any, errorResponse : any) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({
        data: responseJson.data,
        priceLowtoHigh: false,
        priceHightoLow: false,
        newestProduct: true,
        popular: false
      });
    } else {
      this.parseApiCatchErrorResponse(errorResponse);
    }
  }

  getPopularityApiCallIdResponse =  (responseJson : any, errorResponse : any) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({
        data: responseJson.data,
        priceLowtoHigh: false,
        priceHightoLow: false,
        newestProduct: false,
        popular: true
      });
    } else {
      this.parseApiCatchErrorResponse(errorResponse);
    }
  }
 
  modalVisible() {
    this.setState({ isVisible: !this.state.isVisible });
  }
  getListRequest = (token: any) => {
    console.log(this.state.token, "getListRequest");
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  sortPriceAsc = () => {
    const header = {
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSortAscApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.SortAscAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    this.setState({ isVisible: false });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  sortPriceDesc = () => {
    const header = {
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSortDescApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.SortDescAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    this.setState({ isVisible: false });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  sortNewest = () => {
    const header = {
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getsortNewApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.SortNewestAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    this.setState({ isVisible: false });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  sortPopularity = () => {
    const header = {
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPopularityApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.SortPopularityAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    this.setState({ isVisible: false });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End
}
