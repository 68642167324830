import React from "react";

// Customizable Area Start
import { CircularProgress, IconButton, Typography } from "@material-ui/core";
import MainLayout from "../../../components/src/MainLayout.web";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import UnsubscribeIcon from '@material-ui/icons/Unsubscribe';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { withTranslation } from 'react-i18next';
import './notificationspage.css';
import CustomTooltip from "../../../components/src/Tooltip.web";
// Customizable Area End
// Customizable Area Start
import NotificationsController, {
  configJSON,
} from "./NotificationsController";


export class Notifications extends NotificationsController {
  // Customizable Area Start
  // Customizable Area End

  render() {
    const { t } = this.props;
    const storedLanguage = localStorage.getItem('language');
    let notificationMessage;

    if (storedLanguage === 'it') {
        notificationMessage = "Nessuna notifica trovata.";
    } else {
        notificationMessage = "No notification found.";
    }
    return (
      // Customizable Area Start
      <MainLayout
        id="notifications"
        navigation={this.props.navigation}
        useSidebar={false}
      >
        <div className="page-title">
          <div style={{display:'flex'}} >
          <IconButton color="primary" onClick={this.goBack}>
            <ArrowBackIcon />
          </IconButton>

          <Typography variant="h1" component="h1" className="page-title">
            {this.props.t(configJSON.pageTitle)}
          </Typography>
          </div>
          <div >
          <CustomTooltip title={t('ReadAll')}> 
          <IconButton color="primary" onClick={this.readAllNotifications}>
                <DoneAllIcon />
              </IconButton>
          </CustomTooltip>
          </div>
         </div>
        {this.state.loading ? (
          <div className="loading-section">
            <CircularProgress
                color="primary"
                style={{ width: 48, height: 48 }}
            />
          </div>
        ) : (
          <ul className="notification-list">
            {this.state?.notifications && this.state?.notifications.length !== 0 ? this.state?.notifications?.map((notification) => (
              <li className="notification-item" key={notification.id} onClick={() => this.handleNotificationClick(notification)} style={{ cursor: 'pointer' }}>
                <img src={notification.avatar || 'https://picsum.photos/300/300'} alt="" />
                <span>{localStorage.getItem('language') === 'it' ? notification.contents_it: notification.text}</span>
                {notification.is_read === false &&
                    <CustomTooltip title = {t('Unread')}>
                          <IconButton color="primary" test-id='read-button' className="iconbtn"
                           onClick={(evnt : any) => {
                            evnt.stopPropagation();
                            this.readSingleNotifications(notification.id)} }>
                            <UnsubscribeIcon/>
                          </IconButton>
                    </CustomTooltip>
                }           
              </li>
            )): notificationMessage}
          </ul>
        )}
      </MainLayout>
      // Customizable Area End
    );
  }
}
// Customizable Area End
// Customizable Area Start
export default withTranslation()(Notifications)
// Customizable Area End
