Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.pageTitle = 'Log In';
exports.loginFormSubmitButtonText = 'Submit';
exports.emailErrorMessage = 'Please enter correct e-mail ID';
exports.passwordErrorMessage = 'Please enter correct password'
exports.emailFieldLabelName = 'E-mail ID';

exports.contactUsDialogButtonText = 'Contact Us'
exports.contactUsDialogDontHaveAccountText = 'Don\'t have an account';
exports.contactUsDialogTitle = 'Contact Us';

exports.forgotPasswordButtonText = 'Forgot Password?';
exports.forgotPasswordResetSuccessText = 'Password Reset link sent successfully to your email.';
exports.forgotPasswordResetOkText = 'OK';
exports.forgotPasswordResetLinkDescription = 'Enter email to get password reset link';
exports.forgotPasswordGetResetLinkButtonText = 'Get Reset Link';

exports.forgotPasswordDialogTitle = 'Forgot Password';
exports.forgotPasswordSuccessDialogTitle = 'Success';

exports.getApiMethod = 'GET';
exports.apiMethod = 'POST';
exports.apiHeader = { "Content-Type": 'application/json' };

exports.loginApiMethod = 'bx_block_login/login';
exports.fetchProfileMethod = 'bx_block_profile/profiles';
exports.loginForgotPasswordApiMethod = 'bx_block_forgot_password/forgot_passwords';
exports.validateTokenApiMethod = 'bx_block_forgot_password/token_confirmations';
exports.profileApiMethod = 'account_block/accounts';
// Customizable Area End