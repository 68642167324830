import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableWithoutFeedback,
  Platform
} from "react-native";
import { handleWidthCss } from "../../../components/src/constant";
// Customizable Area End

import FilteroptionsController, {
  Props,
  configJSON
} from "./FilteroptionsController";

export default class Filteroptions extends FilteroptionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      <ScrollView keyboardShouldPersistTaps="always" style={styles.container}>
        <TouchableWithoutFeedback
          onPress={() => {
            this.hideKeyboard();
          }}
        >
          {/* Customizable Area Start */}
          {/* Merge Engine UI Engine Code */}
          <View>
            {this.isPlatformWeb() ? (
              <Text
                testID="labelTitle" //Merge Engine::From BDS
                style={styles.titleFilterItemWeb} //UI Engine::From Sketch
              >
                Web Filter Options
              </Text> //UI Engine::From Sketch
            ) : null}
            <Text
              testID="labelBody" //Merge Engine::From BDS
              style={styles.bodyFilterItemWeb} //UI Engine::From Sketch
            >
              {" "}
              {/* UI Engine::From Sketch */}
              Empty Block
            </Text>
          </View>
          {/* Merge Engine UI Engine Code */}
          {/* Customizable Area End */}
        </TouchableWithoutFeedback>
      </ScrollView>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginRight: "auto",
    padding: 16,
    width: handleWidthCss(Platform.OS),
    maxWidth: 650,
    marginLeft: "auto",
    backgroundColor: "#ffffffff"
  },
  titleFilterItemWeb: {
    fontSize: 16,
    marginBottom: 32,
    textAlign: "left",
    marginVertical: 8
  },
  bodyFilterItemWeb: {
    fontSize: 16,
    textAlign: "left",
    marginBottom: 32,
    marginVertical: 8
  },
});
// Customizable Area End
