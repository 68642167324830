import React from 'react';
// Customizable Area Start
import { Button, Typography, Theme } from '@material-ui/core';
import Dialog from './Dialog.web';
import { withTranslation } from "react-i18next";

export class LogoutDialog extends React.Component<IProps, IState> {
    // Customizable Area Start
    private dialogRef = React.createRef<Dialog>();

    constructor(props: IProps) {
        super(props);

        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);

        this.state = {}
    }


    openDialog() {
        if (this.dialogRef.current && typeof this.dialogRef.current.openDialog === 'function') {
            this.dialogRef.current.openDialog();
        }
    }

    closeDialog() {
        if (this.dialogRef.current && typeof this.dialogRef.current.closeDialog === 'function') {
            this.dialogRef.current.closeDialog();
        }
    }

    
    
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { theme, t, badgeContent}=this.props
        const lang = localStorage.getItem("language");
        
        return (
            <>
                <Button
                    variant="text"
                    className={lang === "it" ? "logout-button-it" : "logout-button"}
                    style={{ color: theme.palette.error.main }}
                    onClick={this.openDialog}
                >
                    {t('LogOut')}
                </Button>

                <Dialog
                    id="delete-cart-item-dialog"
                    ref={this.dialogRef}
                    closable={false}
                >
                    <div className="dialog-content">
                        <Typography component="strong">{t("LogOutMessageFirst")} {badgeContent} {t("LogOutMessageSecond")}</Typography>

                        <div className="button-group">
                            <Button variant="contained"  className='deleteBtnSavedQuote' size="large" onClick={this.props.onFormSubmit}>{t('OK')}</Button>

                            <Button
                                variant="outlined"
                                size="large"
                                onClick={this.closeDialog}
                            >
                               {t('CancelText')}
                            </Button>
                        </div>
                    </div>
                </Dialog>
            </>
        )
        // Customizable Area End
    }
}
 // Customizable Area Start
interface IProps {
    onFormSubmit?: () => void,
    onDialogClose?: () => void,
    theme: Theme,
    badgeContent: number,
    t?:any,
}

interface IState {}

export default withTranslation()(LogoutDialog);
// Customizable Area End