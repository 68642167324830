import React from "react";

// Customizable Area Start
import {
  Button,
  Typography,
  CircularProgress,
  Grid,
} from "@material-ui/core";

import { Formik, ErrorMessage } from 'formik';
import MainLayout from '../../../components/src/MainLayout.web';
import ContactUsDialog from '../../../components/src/ContactUs.web';
import CustomInput from '../../../components/src/Input.web';
import ForgotPasswordDialog from './ForgotPasswordDialog.web';
import PasswordField from '../../../components/src/PasswordField.web';
import { Logo } from './assets';
import { classNames } from "../../../components/src/utilities/utils";
import LanguageHelper from "../../../components/src/utilities/LanguageHelper";
import './login.css';
import { msgInfo } from "../../../components/src/constant";

// Customizable Area End

import EmailAccountLoginController, {
  Props,
  configJSON
} from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
     // Customizable Area Start
     // Customizable Area End
    return (
    // Customizable Area Start
      // Required for all blocks
      <MainLayout useHeader={false} useSidebar={false} id="login-page">
        <Grid container className="login-container">
          <Grid item md={6} xs={12} className="left-side" />

          <Grid item md={6} xs={12} className="right-side">
            <div className="right-side-header">
              <img src={Logo} alt="logo" />

              <Typography component="h1" variant="h1" color="primary">
                {LanguageHelper.getLangText(configJSON.pageTitle)}
              </Typography>
            </div>

            <div className="right-side-content">
              <Formik
                onSubmit={this.onFormSubmit}
                initialValues={{ email: "", password: "" }}
              >
                {(props: any) => (
                  <form className="login-form" onSubmit={props.handleSubmit}>
                    <CustomInput
                      name="email"
                      label={LanguageHelper.getLangText(
                        configJSON.emailFieldLabelName
                      )}
                      type="text"
                      fullWidth
                      required
                      onChange={props.handleChange}
                      value={props.values.email}
                      onBlur={this.onLoginFormInputBlur}
                      errorMessage={
                        this.state.isEmailError ? (
                          LanguageHelper.getLangText(
                            configJSON.emailErrorMessage
                          )
                        ) : (
                          <ErrorMessage name="email" />
                        )
                      }
                    />

                    <div
                      className={classNames({
                        "password-field": true,
                        "__has-error":
                          this.state.isPasswordError || !!props.errors.password,
                      })}
                    >
                      <PasswordField
                        name="password"
                        label={LanguageHelper.getLangText(msgInfo.passwordFieldLabelName
                        )}
                        fullWidth
                        required
                        onChange={props.handleChange}
                        value={props.values.password}
                        onBlur={this.onLoginFormInputBlur}
                        errorMessage={
                          this.state.isPasswordError ? (
                            LanguageHelper.getLangText(
                              configJSON.passwordErrorMessage
                            )
                          ) : (
                            <ErrorMessage name="password" />
                          )
                        }
                      />

                      <ForgotPasswordDialog
                        onFormSubmit={this.onForgotPasswordFormSubmit}
                        onDialogClose={this.forgotPasswordDialogClosed}
                        loading={this.state.forgotPasswordFormSubmitLoading}
                        isSubmitted={this.state.forgotPasswordFormIsSubmitted}
                      />
                    </div>

                    <div className="content-footer">
                      <Button
                        className="submit-button"
                        variant="contained"
                        color="primary"
                        fullWidth
                        type="submit"
                        disabled={this.state.formSubmitLoadingStatus}
                      >
                        {this.state.formSubmitLoadingStatus ? (
                          <CircularProgress
                            color="inherit"
                            style={{ width: 28, height: 28 }}
                          />
                        ) : (
                          LanguageHelper.getLangText(
                            configJSON.loginFormSubmitButtonText
                          )
                        )}
                      </Button>

                      <ContactUsDialog
                        description={LanguageHelper.getLangText(
                          configJSON.contactUsDialogDontHaveAccountText
                        )}
                      />
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </Grid>
        </Grid>
      </MainLayout>
    // Customizable Area End
    );
  }
}
// Customizable Area Start
// Customizable Area End