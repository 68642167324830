Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.contactUsDescription = 'contactUsDescription';
exports.contactUsDescriptionForMobileNumber = 'contactUsDescriptionForMobileNumber';

exports.getApiMethod = 'GET';
exports.postApiMethod = 'POST';
exports.patchApiMethod = 'PATCH';
exports.putApiMethod = 'PUT';
exports.apiHeader = { "Content-Type": 'application/json; charset=utf-8' };

exports.fetchProfileMethod = 'bx_block_profile/profiles';
exports.fetchAddressesMethod = 'bx_block_address/addresses';
exports.createAddressMethod = 'bx_block_address/request_addresses';
exports.fetchOperatorName = 'bx_block_profile/show_operators';
exports.changePasswordMethod = 'bx_block_profile/passwords';
exports.validateTokenApiMethod = 'bx_block_forgot_password/token_confirmations';

exports.enterAddressMessage = 'Please, enter a valid address';
exports.yourRequestSubmitted = 'Your request will be submitted to info@pantolonesrl.it';
exports.submitButtonText = 'Submit';
exports.okButtonText = 'OK';
exports.requestPlacedText = 'Request Placed';
exports.requestToNewAddressTitle = 'Request to Add New Address';
exports.passwordRegex = /^(?=.*[A-Za-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
// Customizable Area End