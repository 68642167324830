import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  Popper,
  Paper,
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import InfoIcon from "@material-ui/icons/InfoRounded";
import ProfileFormItem from './ProfileFormItem.web';
import LanguageHelper from '../../../components/src/utilities/LanguageHelper';
import RequestNewAddress from '../../../components/src/RequestNewAddress.web';
import CustomInput from '../../../components/src/Input.web';
import MainLayout from '../../../components/src/MainLayout.web';
import PasswordField from '../../../components/src/PasswordField.web';
import ContactUsDialog from '../../../components/src/ContactUs.web';
import { mergeAddress } from "../../../components/src/utilities/utils";
import { withTranslation } from 'react-i18next';
import './profile.css';

// Customizable Area End

import Customisableuserprofiles2Controller, {
  Props,
  configJSON,
} from "./Customisableuserprofiles2Controller";
import ChangePassword from "./ChangePassword.web";

export class Customisableuserprofiles2 extends Customisableuserprofiles2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { t } = this.props;
    return (
      // Customizable Area Start
      <MainLayout id="profile-page" title={t("Profile")} navigation={this.props.navigation}>
        {this.state.loading === 'FETCHING_PROFILE' ? (
          <div className="center-page loading-profile-section">
            <CircularProgress color="primary" style={{ width: 100, height: 100 }} />
          </div>
        ) : (
          <Grid container className="grid-container">
              <Grid item xs={12} md={6} className="left-side">
                  <ProfileFormItem label={t('CompanyName')} className="name">
                      {this.state.profileData && this.state.profileData.name}
                  </ProfileFormItem>

                  <ProfileFormItem label={t('LegalAddress')} className="legal-address">
                      {(this.state.profileData && this.state.profileData.address_city) && (
                        <>
                          {this.state.profileData && this.state.profileData.address_city},
                          <br />
                          {this.state.profileData && this.state.profileData.address_street}
                          <span> {this.state.profileData && this.state.profileData.address_number},</span>
                          <br />
                          {this.state.profileData && this.state.profileData.address_county},
                          <br />{this.state.profileData && this.state.profileData.address_state},
                          <br />
                          {this.state.profileData && this.state.profileData.address_postcode}.
                        </>
                      )}
                  </ProfileFormItem>

                  <ProfileFormItem label={LanguageHelper.getLangText('VAT')} className="vat">
                    {this.state.profileData && this.state.profileData.vat}
                  </ProfileFormItem>

                  <ProfileFormItem label={t('CustomerID')} className="customer-id">
                    {this.state.profileData && this.state.profileData.customer_code}
                  </ProfileFormItem>

                  <ProfileFormItem
                      label={t('MobileNumber')}
                      className="phone-number-item"
                      labelIcon={(
                          <>
                            <IconButton color="primary" aria-describedby="mobile-phone-info" onClick={this.handlePhonePopper}>
                                  <InfoIcon />
                              </IconButton>

                              <Popper
                                  id="mobile-phone-info"
                                  open={Boolean(this.state.phonePopperAnchor)}
                                  anchorEl={this.state.phonePopperAnchor}
                                  placement="right"
                              >
                                  <Paper elevation={2}>
                                      <ContactUsDialog
                                          className="mobile-phone-info-contact-us"
                                          description={t(configJSON.contactUsDescriptionForMobileNumber)}
                                          useButtonAsAText={true}
                                      />
                                  </Paper>
                              </Popper>
                          </>
                      )}
                  >
                      <Typography component="span" variant="subtitle1">
                          71
                          <ArrowDropDown />
                      </Typography>
                    {this.state.profileData && this.state.profileData.phone}
                  </ProfileFormItem>
                  
                  <ProfileFormItem label={t('OperatorName')} className="operator-name">
                    {this.state.operatorName}
                  </ProfileFormItem>
              </Grid>

              <Grid item xs={12} md={6} className="right-side">
                  <ProfileFormItem label={t('E-mailId')} className="email">
                    {this.state.operatorEmail}
                  </ProfileFormItem>

                  <div className="item password-item">
                      <PasswordField label={t('Password')} fullWidth value="******" readOnly />

                      <ChangePassword
                        loading={this.state.loading === 'UPDATING_PASSWORD'}
                        isSubmitted={this.state.changePasswordFormIsSubmit}
                        formSubmit={(currentPassword: string, newPassword: string) => this.changePassword(currentPassword, newPassword)}
                        closeDialog={() => this.onChangePasswordDialogClose()}
                        errorMessage={this.state.changePasswordErrorMessage}
                      />
                  </div>

                  <div className="item shipping-address-item">
                      <CustomInput
                        className="shipping-address-input"
                        label={t('ShippingAddress')}
                        type="textarea"
                        fullWidth
                        readOnly
                        value={Array.isArray(this.state.addresses) ? this.state.addresses.filter((item) => !!item.address_citya).map((item, index) => `${index + 1}. ${mergeAddress(item)}`).join('\n') : ''}
                      />

                      <ContactUsDialog description={t(configJSON.contactUsDescription)} />

                      <RequestNewAddress
                        loading={this.state.loading === 'CREATING_ADDRESS'}
                        isSubmitted={this.state.createAddressFormIsSubmit}
                        formSubmit={this.createNewAddress}
                        closeDialog={() => this.onCreateAddressDialogClose()}
                      />
                  </div>
              </Grid>
          </Grid>
        )}
      </MainLayout>
      // Customizable Area End
    );
  }
}
export default withTranslation()(Customisableuserprofiles2)
// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
