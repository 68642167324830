import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getToken } from "../../../components/src/utilities/StorageHelper";
import MergeEngineUtilities from "../../utilities/src/MergeEngineUtilities";
import { IAddress } from "../../../components/src/types/types";

export interface IProfile {
  "id": number,
  "first_name": string,
  "last_name": null | string,
  "full_phone_number": string,
  "country_code": null | number,
  "phone_number": null | string,
  "email": string,
  "activated": boolean,
  "device_id": null | number,
  "unique_auth_id": string,
  "password_digest": string,
  "created_at": string,
  "updated_at": string,
  "user_name": null | string,
  "platform": null | string,
  "user_type": null | string,
  "app_language_id": null | string,
  "last_visit_at": null | string,
  "is_blacklisted": boolean,
  "suspend_until": null | string,
  "status": "regular",
  "stripe_id": null | string,
  "stripe_subscription_id": null | string,
  "stripe_subscription_date": null | string,
  "full_name": null | string,
  "role_id": null | string,
  "customer_id": null | string,
  "vat": string,
  "name": string,
  "customer_type": string,
  "address_name": string,
  "phone": string,
  "address_street": string,
  "address_number": string,
  "address_city": string,
  "address_state": string,
  "address_county": string,
  "address_postcode": string,
  "customer_code": string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  t : any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  phonePopperAnchor: HTMLElement | null,
  loading: 'FETCHING_PROFILE' | 'UPDATING_PASSWORD' | 'CREATING_ADDRESS' | '',
  profileData?: IProfile,
  addresses: IAddress[],
  createAddressFormIsSubmit?: boolean
  changePasswordFormIsSubmit?: boolean,
  changePasswordErrorMessage?: string,
  operatorName?: string,
  operatorEmail?: string,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Customisableuserprofiles2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fetchProfileApiCall: string = '';
  fetchAddressesApiCall: string = '';
  createAddressApiCall: string = '';
  changePasswordApiCall: string = '';
  validationApiCallId: string = '';
  fetchOperatorNameCallId: string = '';
  fetchLanguageTextsCallId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.handlePhonePopper = this.handlePhonePopper.bind(this);
    this.fetchProfileData = this.fetchProfileData.bind(this);
    this.createNewAddress = this.createNewAddress.bind(this);
    this.onCreateAddressDialogClose = this.onCreateAddressDialogClose.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.onChangePasswordDialogClose = this.onChangePasswordDialogClose.bind(this);
    this.handleProfileResponse = this.handleProfileResponse.bind(this);
    this.handleCreateAddressResponse = this.handleCreateAddressResponse.bind(this);
    this.handleChangePasswordResponse = this.handleChangePasswordResponse.bind(this);
    this.handleValidationResponse = this.handleValidationResponse.bind(this);
    this.handleFetchAddressesResponse = this.handleFetchAddressesResponse.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      phonePopperAnchor: null,
      loading: 'FETCHING_PROFILE',
      addresses: [],
      operatorName: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (message.id === getName(MessageEnum.SessionResponseMessage)) {
      const token = message.getData(
        getName(MessageEnum.SessionResponseToken)
      );

      if (token) { 
        // add condition
      }
    } else if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      this.setState({
        loading: ''
      }, () => {
        this.handleProfileResponse(responseJson, apiRequestCallId);
        this.handleCreateAddressResponse(responseJson, apiRequestCallId);
        this.handleChangePasswordResponse(responseJson, apiRequestCallId);
        this.handleValidationResponse(responseJson, apiRequestCallId);
        this.handleFetchAddressesResponse(responseJson, apiRequestCallId);
        this.handleOperatorName(responseJson, apiRequestCallId);

        if (this.fetchLanguageTextsCallId === apiRequestCallId) {
          MergeEngineUtilities.handleLanguageResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorReponse);
      })
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    if (MergeEngineUtilities.isLoggedIn()) {
      this.fetchProfileData();
    } else {
      this.validationApiCallId = await MergeEngineUtilities.validateToken(this.props);
    }

    this.fetchLanguageTextsCallId = MergeEngineUtilities.fetchLanguageTexts();
  }

  handleProfileResponse(responseJson: any, apiRequestCallId: string) {
    if (apiRequestCallId !== this.fetchProfileApiCall) { return; }

    if (responseJson && responseJson.data) {
      this.setState({ profileData: { ...responseJson.data.attributes, id: responseJson.data.id } })
    } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJson);
    }
  }

  handleCreateAddressResponse(responseJson: any, apiRequestCallId: string) {
    if (apiRequestCallId !== this.createAddressApiCall) { return; }
    
    if (responseJson && responseJson.address) {
      this.setState({ createAddressFormIsSubmit: true })
    } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJson);
    }
  }

  handleChangePasswordResponse(responseJson: any, apiRequestCallId: string) {
    if (apiRequestCallId !== this.changePasswordApiCall) { return; }

    if (responseJson && responseJson.data) {
      this.setState({ changePasswordFormIsSubmit: true })
    } else if (responseJson && Array.isArray(responseJson.errors)) {
      let allerrors = '';
      responseJson.errors.map((object: string) => {
        const newLocal = JSON.stringify(object);
        JSON.parse(newLocal, (key, value) => {
          if (value.length > 0) {
            if (allerrors.length <= 0) {
              allerrors = value;
            } else {
              allerrors = `${allerrors}{\n}${value}`;
            }
          }
        });
      });

      this.setState({ changePasswordErrorMessage: allerrors })
    }
  }

  handleValidationResponse(responseJson: any, apiRequestCallId: string) {
    if (apiRequestCallId !== this.validationApiCallId) { return; }

    if (
      responseJson
      && Array.isArray(responseJson.messages)
      && responseJson.messages[0]
      && responseJson.messages[0].Token
    ) {
      this.fetchProfileData();
    } else {
      MergeEngineUtilities.logout(this.props);
    }
  }

  handleFetchAddressesResponse(responseJson: any, apiRequestCallId: string) {
    if (apiRequestCallId !== this.fetchAddressesApiCall) { return; }

    if (responseJson && responseJson.addresses) {
      this.setState({ addresses: responseJson.addresses })
    } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJson);
    }
  }

  handleOperatorName(responseJson: any, apiRequestCallId: string) {
    if (apiRequestCallId !== this.fetchOperatorNameCallId) { return; }

    if (responseJson && !responseJson.errors) {
      this.setState({ operatorName: responseJson.first_name, operatorEmail: responseJson.email })
    } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJson);
    }
  }

  handlePhonePopper(event: React.MouseEvent<HTMLElement>) {
    event.persist();

    this.setState((prev) => ({
      ...prev,
      phonePopperAnchor: prev.phonePopperAnchor !== null ? null : event.target as HTMLButtonElement
    }));
  }

  fetchProfileData() {
    const profile_id = MergeEngineUtilities._profile_id;
    const account_id = MergeEngineUtilities._customer_id;

    // Fetching profile
    const requestMessage = MergeEngineUtilities.createRequestMessage({
      endpoint: `${configJSON.fetchProfileMethod}/${profile_id}`,
      method: configJSON.getApiMethod,
      header: {},
      useToken: true,
    })

    this.fetchProfileApiCall = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);

    // Fetching addresses
    const addressRequestMessage = MergeEngineUtilities.createRequestMessage({
      endpoint: `${configJSON.fetchAddressesMethod}?customer_code=${profile_id}`,
      method: configJSON.getApiMethod,
      header: {},
      useToken: true,
    })

    this.fetchAddressesApiCall = addressRequestMessage.messageId;

    runEngine.sendMessage(addressRequestMessage.id, addressRequestMessage);

    // Fetching operator name
    const fetchOperatorNameMessage = MergeEngineUtilities.createRequestMessage({
      endpoint: `${configJSON.fetchOperatorName}?id=${account_id}`,
      method: configJSON.getApiMethod,
      header: {},
      useToken: true,
    })

    this.fetchOperatorNameCallId = fetchOperatorNameMessage.messageId;

    runEngine.sendMessage(fetchOperatorNameMessage.id, fetchOperatorNameMessage);
  }

  createNewAddress(address: string) {
    this.setState({
      loading: 'CREATING_ADDRESS'
    }, () => {
      const profile_id = MergeEngineUtilities._profile_id;

      const requestMessage = MergeEngineUtilities.createRequestMessage({
        endpoint: configJSON.createAddressMethod,
        method: configJSON.postApiMethod,
        data: {
          request_address: {
            full_address: address,
            customer_code: profile_id
          }
        },
        header: {},
        useToken: true,
      })

      this.createAddressApiCall = requestMessage.messageId;

      runEngine.sendMessage(requestMessage.id, requestMessage);
    })
  }

  onCreateAddressDialogClose() {
    this.setState({
      loading: '',
      createAddressFormIsSubmit: false
    })
  }

  changePassword(currentPassword: string, newPassword: string) {
    this.setState({
      loading: 'UPDATING_PASSWORD',
      changePasswordErrorMessage: ''
    }, async () => {
      const token = await getToken();
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.changePasswordApiCall = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.changePasswordMethod}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.patchApiMethod
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({ ...configJSON.apiHeader, token })
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          data: {
            current_password: currentPassword,
            new_password: newPassword
          }
        })
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    })
  }

  onChangePasswordDialogClose() {
    this.setState({
      loading: '',
      changePasswordFormIsSubmit: false
    })
  }

  // Customizable Area End
}
