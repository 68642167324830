// Customizable Area Start

export const msgInfo = {
        passwordErrorMessage : 'Please enter correct password',
        passwordFieldLabelName: 'Password'
}

export const emailRegex =  new RegExp("\\w+");
export const passwordRegex =  new RegExp("\\w+");
export const phoneRegexExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;


export const handleWidthCss = (val : string) => {
    return val === "web" ? "75%" : "100%"
}

export const handleBorderCss = (val : string) => {
    return val === "web" ? 0 : 1
}

export const handleImgShowHideCss = (val : string) => {
    return val === "web" ? { height: 30, width: 30 } : {}
}

// Customizable Area End