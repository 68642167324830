import { IAddress } from "../types/types";

export const hexToRgba = (hex: string): number[] => {
    let num = parseInt(hex.slice(1), 16); // Convert to a number
    return [num >> 16 & 255, num >> 8 & 255, num & 255, num >> 24 & 255];
}

export const getHexAsRgba = (hex: string, alpha: number): string => {
    const rgba = hexToRgba(hex);
    return `rgba(${rgba[0]}, ${rgba[1]}, ${rgba[2]}, ${alpha})`;              
}

export const safeJSONparse = <T>(str: string, defValue: any = {}): T => {
    let result = null;

    try {
        result = JSON.parse(str);
    } catch(err) {}

    return result || defValue;
}


export const classNames = (classes: { [key in string]: boolean }) => {
    let className = '';
    
    Object.entries(classes).forEach(([key, value]) => {
        if (value === true) {
            className += `${key} `;
        }
    });

    return className;
}

export const isValidEmail = (email: string) : boolean => {
    const res = email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    return Array.isArray(res) && res.length > 0;
}

export const insertParam = (key: string, value: any) => {
    key = encodeURIComponent(key);
    value = encodeURIComponent(value);

    // kvp looks like ['key1=value1', 'key2=value2', ...]
    var kvp = document.location.search.substr(1).split('&');
    let i=0;

    for(; i<kvp.length; i++){
        if (kvp[i].startsWith(key + '=')) {
            let pair = kvp[i].split('=');
            pair[1] = value;
            kvp[i] = pair.join('=');
            break;
        }
    }

    if(i >= kvp.length){
        kvp[kvp.length] = [key,value].join('=');
    }

    // can return this or...
    let params = kvp.join('&');
    if (params.charAt(0) === '&') {
        params = params.substring(1);
    }

    // changing url without refreshing
    window.history.replaceState("", "", `${window.location.origin}${window.location.pathname}?${params}${window.location.hash}`)
}

export const removeQueryParam = (key: string) => {
    const regexp = new RegExp(`${key}=\\w*`,'gmi');
    window.history.replaceState("", "", window.location.href.replace(regexp, ''));
}

type queryParam = {
    key: string,
    value: string | number
}

export const getQueryParams = (): queryParam[] => {
   return (
        window.location.search
            .substring(1)
            .split('&')
            .map((item) => {
                const [key, value] = item.split('=')
                return {
                    key: decodeURIComponent(key) as queryParam['key'] ,
                    value: decodeURIComponent(decodeURIComponent(value))
                }
            })
   )
}

export const clearQueryParams = () => {
    window.history.replaceState("", "", `${window.location.origin}${window.location.pathname}`)
}

export const addHashToUrl = (hash: string) => {
    if (!window) { return; }
    window.location.hash = hash;
}

export const isHashExist = (hash: string) => {
    if (!window) { return false; }
    return window.location.hash === `#${hash}`;
}

export const clearHash = () => {
    if (!window) { return; }
    window.location.hash = ''; 
}

export const formatPrice = (price: string | number, useThousandSeperator: boolean = true) => {
    if (!price) { return null; }
    const p = typeof price === 'string' ? parseFloat(price) : price;
        const factor = 1e5;
        const roundedNum = Math.round(p * factor) / factor;
        return new Intl.NumberFormat("de-DE",{maximumFractionDigits:5,}).format(roundedNum);
}

export const mergeAddress = (item: IAddress) => {
    return `${item.address_citya || ''} ${item.address_streeta || ''} ${item.address_numbera || ''} ${item.address_countya || ''} ${item.address_statea || ''} ${item.address_postcodea || ''}`
}

export function throttle(callback: Function, delay: number) {
    let shouldWait = false;

    return (...args: any[]) => {
      if (shouldWait) return;
  
      callback(...args);
      shouldWait = true;
      setTimeout(() => {
        shouldWait = false;
      }, delay);
    };
}

export function debounce(cb: Function, delay = 250) {
    let timeout: any;
  
    return (...args: any[]) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        cb(...args)
      }, delay)
    }
  }