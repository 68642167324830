import React from 'react';
import { Button, Grid, withTheme, Theme, Badge, Typography, IconButton } from '@material-ui/core';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCartOutlined';
import Input from './Input.web';
import MergeEngineUtilities from '../../blocks/utilities/src/MergeEngineUtilities';
import { clearHash, clearQueryParams, insertParam } from './utilities/utils';
import { Logo } from '../../blocks/email-account-login/src/assets';
import Languageswitch from '../../blocks/languageswitch/src/Languageswitch.web';
import { withTranslation } from 'react-i18next';
import LogOutDialogWeb from './LogOutDialog.web';

class Header extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.logout = this.logout.bind(this);
        this.searchProduct = this.searchProduct.bind(this);
        this.onSearchInputChange = this.onSearchInputChange.bind(this);
        this.navigateToHome = this.navigateToHome.bind(this);
        this.navigateToCart = this.navigateToCart.bind(this);
        this.navigateToNotifications = this.navigateToNotifications.bind(this);

        this.state = { searchInputValue: '', cartCount: props.cartCount || 0  }
    }

    async componentDidMount() {
        const lang = localStorage.getItem('language')
        if(!lang) {
              localStorage.setItem('language', "en")
        }
        const cid = localStorage.getItem('customer_id');
        const cartCountString = localStorage.getItem(`cart_${cid}`);
        
        if (cartCountString) {
            const cartCount = JSON.parse(cartCountString);
            const productsLength = Object.keys(cartCount.products).length;
            this.setState({ cartCount: productsLength });
        } else {
            this.setState({ cartCount: 0 });
        }       
    }

    logout() {
        MergeEngineUtilities.logout(this.props);
        const cid = localStorage.getItem('customer_id');
        localStorage.removeItem(`cart_${cid}`);
        localStorage.removeItem(`cartData`);
    }

    onSearchInputChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ searchInputValue: event.target.value.trim() });
    }

    searchProduct(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const value = this.state.searchInputValue;
    
        if (!value) {
            return MergeEngineUtilities.navigateToScreen('LandingPage', this.props);
        }

        if (
            window &&
            (window.location.search.indexOf('search_value') > -1 || window.location.pathname === '/Catalogue')
        ) {
            clearQueryParams();
            insertParam('search_value', value);
            return window.location.reload();
        }
        
        MergeEngineUtilities.navigateToScreen(
            'Catalogue',
            this.props,
            { searchValue: value }
        );
    }

    navigateToHome() {
        if (
            window &&
            (window.location.search.indexOf('LandingPage') > -1 || window.location.pathname === '/LandingPage')
        ) {
            return clearHash();
        }
        
        MergeEngineUtilities.navigateToScreen(
            'LandingPage',
            this.props
        );
    }

    navigateToCart() {
        MergeEngineUtilities.navigateToScreen('ShoppingCartOrders', this.props, { navigateFrom: 'cart' })
    }

    navigateToNotifications() {
        window.location.href='/Notifications'
    }

    render() {
        const { theme, productSearchText,t } = this.props;
        const lang = localStorage.getItem("language")
        const displayCount =  localStorage.getItem('Notification_count');

        return (
            <header>
                <Grid
                    container
                    className="header-wrapper"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item className="header-wrapper-left-side" lg={6} md={6} sm={6} xs={8}>
                        <Button
                            variant="text"
                            disableElevation
                            disableRipple
                            disableFocusRipple
                            disableTouchRipple
                            onClick={this.navigateToHome}
                            className="logo-button"
                        >                          
                            <img src={Logo} alt="logo"></img>
                        </Button>

                        <form autoComplete='off' onSubmit={this.searchProduct}>
                            <Input
                                defaultValue={productSearchText}
                                type="search"
                                name="product-search"
                                placeholder={t('SearchProduct')}
                                autoComplete="off"
                                onInput={this.onSearchInputChange}
                                fullWidth
                            />
                        </form>
                    </Grid>

                    <Grid item className="header-wrapper-right-side" lg={6} md={6} sm={4} xs={4}>
                        <div className="top">
                            <div className="language-selector">                              
                                <Languageswitch />
                            </div>
                            {
                                this.props.cartCount ?? this.state.cartCount > 0 ? (<LogOutDialogWeb onFormSubmit={() => this.logout()} theme={theme} badgeContent={this.props.cartCount ?? this.state.cartCount}/>) : (
                                    <Button
                                        variant="text"
                                        className={lang === "it" ? "logout-button-it" : "logout-button"}
                                        style={{ color: theme.palette.error.main }}
                                        onClick={this.logout}
                                    >
                                        {t('LogOut')}
                                    </Button>
                                )
                            }
                            
                        </div>
                        <div className="bottom">
                            <Button color="primary" onClick={this.navigateToNotifications}>  
                                <Badge badgeContent={displayCount} color="error" className= {localStorage.getItem('Notification_count') === '0'? 'badge-none': "badge-block"}>
                                   <NotificationsNoneIcon /> 
                                </Badge>                                                         
                                <Typography component="small" color="textPrimary">
                                    {t('Notification')}
                                </Typography>
                            </Button>

                            <Button color="primary" onClick={this.navigateToCart}>
                                <Badge badgeContent={this.props.cartCount ?? this.state.cartCount} color="error">
                                    <ShoppingCartIcon />
                                </Badge>

                                <Typography component="small" color="textPrimary">
                                    {t('Cart')}
                                </Typography>
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </header>
        )
    }
}

interface IProps {
    theme: Theme,
    navigation: any,
    productSearchText?: string,
    cartCount?: number,
    t?:any
}

interface IState {
    searchInputValue: string,
    cartCount: number
}

export default withTranslation()(withTheme(Header));