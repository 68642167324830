import React from "react";
// Customizable Area Start
import {
  Button,
  Typography,
  CircularProgress
} from "@material-ui/core";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Filters from "./Filters.web";
import ProductsTable from "./ProductsTable.web";
import MainLayout from "../../../components/src/MainLayout.web";
import { classNames } from "../../../components/src/utilities/utils";
import { withTranslation } from 'react-i18next';
import RequestProduct from "./RequestProduct";
import "./catalogue.css";

// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";

export class Catalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  clearFilters = () => {
    // Reset all filter-related state variables
    this.setState({
      selectedMaterialIds: [],
      selectedDiameterIds: [],
      selectedThicknessIds: [],
      selectedRatingIds: [],
      products: this.state.allProducts // Reset to show all products
    });
  };
  // Customizable Area End

  render() {
     // Customizable Area Start
  const { t }= this.props;
  const { filterApiData, loading, products, searchValue } = this.state;

  // Check if the loading state is null or still in progress
  const isLoading = loading === 'INITIAL' || loading === 'CATALOGUE';
  
  // Determine if no products are found (ensure it's not loading and products array is empty)
  const isNoProductsFound = (!isLoading && products.length === 0);
    return (
      <MainLayout
        id="catalogue"
        navigation={this.props.navigation}
        useSidebar={isNoProductsFound}
        productSearchText={this.state.searchValue}
        cartCount={Object.keys(this.state.cart.products).length}
      >
  <div className={classNames({
              'products-header': true,
              '__has-search-value': !!this.state.searchValue
            })}>
              <div className="breadcrumb" data-test-id="breadcrumb">
  <Button 
    variant="text" 
    onClick={() => this.redirectToLandingPage(false)} 
    data-test-id="category-button"
  >
    {this.state.categoryName}
  </Button>
  <ChevronRightIcon data-test-id="chevron-right-1" />
  <Button 
    variant="text" 
    onClick={() => this.redirectToLandingPage(true)} 
    data-test-id="subcategory-button"
  >
    {this.state.subCategoryName}
  </Button>
  <ChevronRightIcon data-test-id="chevron-right-2" />
  <Typography component="strong" data-test-id="product-typography">
    {t("Product")}
  </Typography>
</div>
            </div>


        {isNoProductsFound ? (
          <>
          <div className="catalogue-no-products-found">{!searchValue? t("NoProductFound"):t("NoProductsFound")}</div>
          <RequestProduct
          data-test-id="requestProductcallid"
                isRequestProductLoading={this.state.isRequestProductDialogLoading}
                isRequestProductSubmitted={this.state.isRequestProductDialogSubmitted}
                onFormSubmit={this.onRequestProductFormSubmit}
                onDialogClose={this.onRequestProductDialogClosed}
                isNewProductsLoading={this.state.loading === 'NEW_PRODUCTS'}
              />  
          </>

        ) : (
          <>
             {!searchValue && (
              <>
               <div className="titleFlex">
               <Typography variant="h1" component="h1" className="page-title">{t("Filter")}</Typography>
               <Button className="clear-button-filter" onClick={this.clearFilters} color="primary" variant="contained">Clear</Button>
               </div>
                <Filters
                  filterItems={filterApiData.material}
                  filterType="MATERIAL"
                  valueIds={this.state.selectedMaterialIds}
                  onItemClicked={this.changeFilterId}
                />

                <Filters
                  filterItems={filterApiData.diameter}
                  filterType="DIAMETER"
                  valueIds={this.state.selectedDiameterIds}
                  onItemClicked={this.changeFilterId}
                />

                <Filters
                  filterItems={filterApiData.thickness}
                  filterType="THICKNESS"
                  valueIds={this.state.selectedThicknessIds}
                  onItemClicked={this.changeFilterId}
                />
                
                <Filters
                  filterItems={filterApiData.rating}
                  filterType="RATING"
                  valueIds={this.state.selectedRatingIds}
                  onItemClicked={this.changeFilterId}
                />
              </>
            )}

            {(this.state.loading === 'INITIAL' || this.state.loading === 'CATALOGUE') ? (
              <div className="products-table-loading">
                <CircularProgress
                  color="primary"
                  style={{ width: 64, height: 64 }}
                />
              </div>
            ) : (
              <ProductsTable
              data-test-id="productsTablecallid"
                products={this.state.products}
                cart={this.state.cart}
                isRequestProductLoading={this.state.isRequestProductDialogLoading}
                isRequestProductSubmitted={this.state.isRequestProductDialogSubmitted}
                onFormSubmit={this.onRequestProductFormSubmit}
                onDialogClose={this.onRequestProductDialogClosed}
                productSearchValue={this.state.searchValue}
                onCartItemUpdate={this.updateProductQuantityFromCart}
                fetchCatalogue={this.fetchCatalogue}
                isNewProductsLoading={this.state.loading === 'NEW_PRODUCTS'}
              />          
            )}
          </>
        )}
      </MainLayout>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export default withTranslation()(Catalogue)
// Customizable Area End 