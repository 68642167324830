import React from 'react';
// Customizable Area Start
import { Button, Typography, CircularProgress } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import LanguageHelper from '../../../components/src/utilities/LanguageHelper';
import Dialog from '../../../components/src/Dialog.web'
import PasswordField from '../../../components/src/PasswordField.web';
import { withTranslation } from 'react-i18next';
import { configJSON } from './Customisableuserprofiles2Controller';
// Customizable Area End
// Customizable Area Start
export class ChangePassword extends React.Component<IProps, IState> {
    private dialogRef = React.createRef<Dialog>();

    constructor(props: IProps) {
        super(props);

        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.enterPassword = this.enterPassword.bind(this);
        this.onCurrentPasswordInputBlur = this.onCurrentPasswordInputBlur.bind(this);
        this.onNewPasswordInputBlur = this.onNewPasswordInputBlur.bind(this);
        this.state = { currentPassword: '', newPassword: '', errors: {
            errorCurrentPassword :'',
            errorNewPassword : '',
        }}
    }

    openDialog() {
        if (this.dialogRef.current && typeof this.dialogRef.current.openDialog === 'function') {
            this.dialogRef.current?.openDialog();
        }
    }

    closeDialog(isSelfClose = false) {
        this.setState({
            currentPassword: '',
            newPassword: '',
            errors:{
                errorCurrentPassword:'',
                errorNewPassword:'',
            }
        }, () => {
            this.props.closeDialog();

            if (isSelfClose) {
                this.dialogRef.current?.closeDialog();
            }
        });
    }

    onCurrentPasswordInputBlur(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ currentPassword: event.target.value });
    }

    onNewPasswordInputBlur(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ newPassword: event.target.value });
    }

    enterPassword() {
        if(this.validateForm()){
            this.props.formSubmit(this.state.currentPassword, this.state.newPassword);
        }
    }

    validateForm = () => {
    let tempErrors: {
    errorCurrentPassword: string | undefined;
    errorNewPassword: string | undefined;
    } =
    { ...this.state.errors }

    if (!this.state.currentPassword) {
     tempErrors.errorCurrentPassword = this.props.t('FieldNotEmptyNote') as string;
    } else if (!configJSON.passwordRegex.test(this.state.currentPassword)) {
      tempErrors.errorCurrentPassword = this.props.t('PasswordNote') as string;;
    } else {
      delete tempErrors?.errorCurrentPassword;
    }

    if (!this.state.newPassword) {
        tempErrors.errorNewPassword =  this.props.t('FieldNotEmptyNote') as string;
      } else if (!configJSON.passwordRegex.test(this.state.newPassword)) {
        tempErrors.errorNewPassword = this.props.t('PasswordNote') as string;;
      } else {
        delete tempErrors?.errorNewPassword;
    }
    this.setState({
        errors : tempErrors
    })
    return Object.keys(tempErrors).length === 0;
  };

// Customizable Area End
    render() {
        // Customizable Area Start
        const {t}=this.props
                return (
            <>  
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.openDialog()}
                >
                    {t('ChangePassword')}
                </Button>

                <Dialog
                    id="request-new-address-dialog"
                    title={this.props.isSubmitted ? t('Success') : t('NewPassword')}
                    ref={this.dialogRef}
                    onClose={this.closeDialog}
                    closable
                    disablePortal
                >
                    {this.props.isSubmitted ? (
                        <div className="new-address-success-section">
                            <CheckCircle className="check-icon" />

                            <Typography variant="body2" className="description">
                                {t('ChangedSuccessfully')}
                            </Typography>

                            <Button
                                className="rounded-button"
                                variant="contained"
                                color="primary"
                                onClick={() => this.closeDialog(true)}
                                fullWidth
                            >
                                OK
                            </Button>
                        </div>
                    ) : (
                        <div className="new-address-section">
                            {this.props.loading === true ? (
                                <div className="loading-section">
                                    <CircularProgress
                                        color="primary"
                                        style={{ width: 64, height: 64 }}
                                    />
                                </div>
                            ) : (
                                <>
                                    <PasswordField
                                        value={this.state.currentPassword}
                                        label={t('CurrentPassword')}
                                        onBlur={this.onCurrentPasswordInputBlur}
                                        onChange={this.onCurrentPasswordInputBlur}
                                        fullWidth
                                        errorMessage={<Typography className="error-message" color="error" style={webstyle.validationErrorNote}>{this.state.errors.errorCurrentPassword}</Typography>}
                                    />

                                    <PasswordField
                                        value={this.state.newPassword}
                                        label={t('NewPassword')}
                                        onBlur={this.onNewPasswordInputBlur}
                                        onChange={this.onNewPasswordInputBlur}
                                        fullWidth
                                        errorMessage={<Typography className="error-message" color="error" style={webstyle.validationErrorNote}>{this.state.errors.errorNewPassword}</Typography>}
                                    />

                                    {this.props.errorMessage && (
                                        <Typography className="error-message" color="error">
                                            {LanguageHelper.getLangText(this.props.errorMessage)}
                                        </Typography>
                                    )}

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.enterPassword}
                                        fullWidth
                                        data-test-id="formSumbitBtn"
                                    >
                                        {t('Submit')}
                                    </Button>
                                </>
                            )}
                        </div>
                    )}
                </Dialog>
            </>
        )
        // Customizable Area End
    }
}
// Customizable Area Start
interface IProps {
    loading?: boolean,
    isSubmitted?: boolean,
    formSubmit: (currentPassword: string, newPassword: string) => void,
    closeDialog: () => void,
    errorMessage?: string,
    t?:any
}

interface IState {
    currentPassword: string,
    newPassword: string,
    errors: {
        errorCurrentPassword: string | undefined;
        errorNewPassword: string | undefined,
    }
}

export default withTranslation()(ChangePassword);

const webstyle = {
    validationErrorNote: {
        textAlign: "left" as "left",
    }
}
// Customizable Area End