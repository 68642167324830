import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MergeEngineUtilities from "../../utilities/src/MergeEngineUtilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  t:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: any[];
  selectedData: any;
  token: any;
  notifications: {
    contents_it: string;
    is_read: boolean; id: number; avatar: string; text: string 
  }[];
  loading: boolean;
  notificationCount:string, 
  notificationClickread : boolean; 
  navigateToOrderId : string ;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fetchNotificationsCallId: string = "";
  validationApiCallId: string = "";
  readSingleNotificationsCallId: string = "";
  readAllNotificationsCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      data: [],
      selectedData: null,
      token: "",
      notifications: [],
      loading: false,
      notificationCount:"" , 
      notificationClickread : false , 
      navigateToOrderId : ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.getNotifications = this.getNotifications.bind(this);
    this.handleValidationResponse = this.handleValidationResponse.bind(this);
    this.goBack = this.goBack.bind(this);
    this.readSingleNotifications = this.readSingleNotifications.bind(this);
    this.readAllNotifications = this.readAllNotifications.bind(this);
    // Customizable Area End
  }

  async componentDidMount() {
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    if (MergeEngineUtilities.isLoggedIn()) {
      this.getNotifications();
    } else {
      this.validationApiCallId = await MergeEngineUtilities.validateToken(
        this.props
      );
    }
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      this.handleValidationResponse(responseJson, apiRequestCallId);
      this.handleNotificationsResponse(responseJson, apiRequestCallId);
      this.parseApiCatchErrorResponse(errorReponse);
      this.handleSingleNotificationsResponse(responseJson, apiRequestCallId)
      this.handleReadAllNotificationsResponse(responseJson, apiRequestCallId)
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleValidationResponse(responseJson: any, apiRequestCallId: string) {
    if (apiRequestCallId === this.validationApiCallId) {
      if (
        responseJson &&
        Array.isArray(responseJson.messages) &&
        responseJson.messages[0] &&
        responseJson.messages[0].Token
      ) {
        this.getNotifications();
      } else {
        MergeEngineUtilities.logout(this.props);
      }
    }
  }

  handleNotificationsResponse(responseJson: any, apiRequestCallId: string) {
    if (apiRequestCallId === this.fetchNotificationsCallId) { 
      const newArray = responseJson?.data?.map((item: { id: number; attributes: { is_read: boolean; contents: string; contents_it: string, order_id : number;}; }) => ({
        id: item.id ,
        avatar: '',
        text: item.attributes.contents,
        is_read: item.attributes.is_read,
        contents_it: item.attributes.contents_it ,
        order_id : item.attributes.order_id
      }));
      this.setState({ loading: false });
      this.setState({notifications: newArray})
      if (responseJson?.data?.length === undefined) {
        localStorage.setItem("Notification_count", '0') 
      }else{
        localStorage.setItem("Notification_count",responseJson?.data?.length) 
      }    
    }
  }

  handleSingleNotificationsResponse(responseJson: any, apiRequestCallId: string) {
    if (apiRequestCallId === this.readSingleNotificationsCallId) { 
      this.setState({loading: false})
      this.setState(() => ({
        //@ts-ignore
        notificationCount:(parseInt(localStorage.getItem('Notification_count')) - 1).toString(),
      }), () => {
        if(this.state.notificationClickread) {
          window.location.href = `/OrderManagement#${this.state.navigateToOrderId}`;
        }
        else {
          this.getNotifications(); 
        }  
      }); 
      localStorage.setItem("Notification_count",  this.state.notificationCount) 
    
    }
  }
  
  handleReadAllNotificationsResponse(responseJson: any, apiRequestCallId: string) {
    if (apiRequestCallId === this.readAllNotificationsCallId) {
      this.setState({loading: false})     
      this.getNotifications()
      localStorage.setItem("Notification_count", '0') 
    }
  }

  getNotifications() {
    this.setState({ loading: true });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchNotificationsCallId= requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.fetchNotificationsApiCallId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({ ...configJSON.apiHeader, token: localStorage.getItem("authToken") })
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleNotificationClick = (notification: any) => {
    
    this.setState(
      {notificationClickread : true , 
        navigateToOrderId : notification.order_id
      } ,
       () => this.readSingleNotifications(notification.id) )
  };

  readSingleNotifications(id:number) {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.readSingleNotificationsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.fetchNotificationsApiCallId}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({ ...configJSON.apiHeader, token: localStorage.getItem("authToken") })
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  readAllNotifications() {
    this.setState({ loading: true });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.readAllNotificationsCallId= requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.readAllNotificationsApiCallId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({ ...configJSON.apiHeader, token: localStorage.getItem("authToken") })
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goBack() {
    this.props.navigation.goBack();
  }
  // Customizable Area End
}
