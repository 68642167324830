import React from "react";
// Customizable Area Start
import {
  ScrollView,
  Image,
  Text,
  SafeAreaView,
  TouchableOpacity,
  StyleSheet,
  TextInput
} from "react-native";

import scale, { verticalScale } from "../../../components/src/Scale";

import PostCreationController from "./PostCreationCommonController";
import { edit } from "../src/assets";
import Select from "react-select";
// Customizable Area End

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PostCreation extends PostCreationController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <SafeAreaView style={styles.safeAreaView}>
        <ScrollView>
          {/* Customizable Area Start */}
          <TouchableOpacity
            style={{ marginTop: 10 }}
            onPress={this.chooseImage}
            testID={"textProductImageUpload"}
          >

            {this.state.image ? (
              <Image
                source={{ uri: this.state.image }}
                style={styles.imageStyle}
              />
            ) : this.state.profileImageData && (
              <Image
                source={{ uri: this.state.profileImageData.data }}
                style={styles.imageStyle}
              />
            )}
            <Image source={edit}
              style={styles.editIcon} />
          </TouchableOpacity>

          <Select
            options={this.state.allCategories}
            onChange={this.handleCatChangeChange}
            value={this.state.selectedCategory}
          />

          <TextInput
            value={this.state.name}
            style={[styles.inputTextField1]}
            testID={"textProductName"}
            placeholder="Product Name"
            {...this.txtInputProductNameProps} //Merge Engine::From BDS - {...this.testIDProps}
          />


          <TextInput
            placeholder="Description"
            multiline={true}
            numberOfLines={10}
            testID={"textProductDiscripation"}
            style={[styles.inputTextField]}
            {...this.txtInputProductDiscripationProps}
            value={this.state.description}
          />


          <TextInput
            testID={"textProductPrice"}
            keyboardType={"numeric"}
            placeholder="Price"
            value={this.state.price}
            style={[styles.inputTextField1]}
            {...this.txtInputProductPriceProps}
          />


          {!this.state.id ? (
            <TouchableOpacity
              style={[styles.buttonStyle]}
              onPress={() => this.createPostCreation()}
              testID={"clickCreatePostButton"}
            >
              <Text style={styles.buttonText}>Create Post</Text>
            </TouchableOpacity>
          ) : (
            <TouchableOpacity
              style={[styles.buttonStyle]}
              testID={"clickUpadtePostButton"}
              onPress={() => this.updateCreatePostData(this.state.id)}
            >

              <Text style={styles.buttonText}>Update Post</Text>
            
            </TouchableOpacity>

          )}
          {/* Customizable Area End */}
        </ScrollView>
      </SafeAreaView>
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  inputTextField1: {
    fontSize: scale(16.7),
    paddingLeft: 10,
    borderBottomWidth: 0.3,
    color: "black",
    borderWidth: 1,
    height: verticalScale(50),
    margin: scale(3),
    paddingRight: 10,
    borderColor: "grey",
  },
  
  inputTextField: {
    marginTop: verticalScale(5),
    fontSize: scale(16.7),
    borderWidth: 1,
    borderBottomWidth: 0.3,
    margin: scale(3),
    height: verticalScale(80),
    padding: 10,
    borderColor: "grey",
    color: "black",
  },
  imageStyle: {
    borderWidth: 1,
    borderRadius: scale(50),
    marginBottom: verticalScale(25),
    height: scale(100),
    marginTop: verticalScale(5),
    opacity: 0.6,
    borderColor: "black",
    width: scale(100),
  },

  

  buttonStyle: {
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 22,
    backgroundColor: "#366ef9",
    height: scale(50),
    marginTop: verticalScale(20)
  },
  
  buttonText: {
    color: "white"
  },
  safeAreaView: {
    flex: 1,
    alignSelf: "center",
    width: "90%"
  },
  editIcon: {
    position: "absolute",
    height: scale(20),
    width: scale(20),
    resizeMode: "contain",
    top: verticalScale(90),
    left: scale(90)
  }
});
// Customizable Area End
